<template>
    <section class="customs-totals">
        <div class="customs-totals.item">
            <span class="customs-totals.item.label">Total item weight:</span>
            <span class="customs-totals.item.value">{{ this.mainstore.customsTotalWeight }}Kg</span>
        </div>
        <div class="customs-totals.item">
            <span class="customs-totals.item.label">Total item value:</span>
            <span class="customs-totals.item.value">£{{ this.mainstore.customsTotalValue }}</span>
        </div>
        <div class="popover-warning" v-if="errorMessage !== 'No error'">
            {{ errorMessage }}
        </div>
    </section>
</template>

<script>
    import { useMainStore } from '@/stores/MainStore.ts';

    export default {
        setup() {
            const mainstore = useMainStore()
            return { mainstore }
        },

        name: 'CustomsTotals',

        computed: {
            errorMessage() {
                const totalValue = parseFloat(this.mainstore.customsTotalValue); // Convert customsTotalValue to a number
                if (this.mainstore.selected.weight < (this.mainstore.customsTotalWeight * 1000)) {
                    return 'The weights entered do not match the weight you initially entered. Please ensure you have entered the correct weights for each item.';
                
                }
                if (this.mainstore.selected.additionalInsurance.value / 100 < totalValue) {
                    return 'Your total item value is greater than your insurance level. It is your responsibility to ensure the correct information is provided and your item is insured to an appropriate level.';
                } 
                return 'No error';
            }
        }
    }
</script>