<template>
  <div :class="['page-title', className]">
    <h2 class="page-title.title">
      {{ titleText }}
      <span
        class="page-title.title.form-info"
        v-if="
          this.$route.name === 'recipient' ||
          this.$route.name === 'sender' ||
          this.$route.name === 'customs'
        "
      >
        Fields marked with
        <span class="page-title.title.form-info.required"> * </span>
        are required
      </span>
    </h2>
  </div>
</template>

<script>
export default {
  name: "PageTitle",

  // to make the component a little easier to update, the following computed properties map the route to the correct content. The images are set up in the SASS file for this component as background images.

  computed: {
    titleText() {
      const routeMap = {
        provideItems: "Choose your postage method",
        parcelSize: "Please choose your letter or parcel size",
        services: "Please choose your postal service",
        recipient: "Where would you like to send your item(s)?",
        sender: "Please provide the senders information",
        customs: "Please provide your customs information",
        orderSummary: "Order summary",
        safeToFly: "Safe to Post",
        basket: "Basket",
        labels: "Labels",
        parcelpostboxes: "Parcel postboxes",
        collection: "Collections",
        howto: "How to use Online Postage",
        termsandconditions: "Terms and Conditions",
        faq: "Frequestly asked Questions (FAQ)",
        confirmation: "Thank you for purchasing from Isle of Man Post Office | Online Postage",
        privacypolicy: "Privacy Policy",
        cookiepolicy: "Cookie Policy",
      };
      return routeMap[this.$route.name] || "";
    },
    className() {
      const routeMap = {
        provideItems: "--method",
        parcelSize: "--parcel-size",
        services: "--service",
        recipient: "--recipient",
        sender: "--sender",
        customs: "--customs",
        orderSummary: "--summary",
        safeToFly: "--safe-to-fly",
        basket: "--basket",
        labels: "--labels",
        parcelpostboxes: "--parcel-postboxes",
        collection: "--collection",
        howto: "--how-to",
        termsandconditions: "--terms-conditions",
        faq: "--faq",
        confirmation: "--order-confirmation",
        privacypolicy: "--privacy",
        cookiepolicy: "--privacy"
      };
      return routeMap[this.$route.name] || "";
    }
  }
};
</script>
