<template>
    <aside class="order-sidebar" v-if="mainstore.selected.destination?.name">
        <div class="order-sidebar.summary">
            <button @click="handleClick" class="small-button"><span class="small-button.text">Reset all</span></button>
            <h3 class="order-sidebar.summary.title">Order Summary</h3>
            <div class="order-sidebar.consignment" v-if="basketstore.consignmentType">
                <div class="order-sidebar.consignment.image">
                    <img :src="require('@/assets/img/parcel-postbox.png')" v-if="basketstore.consignmentType === 'DROP_AT_POSTBOX'" />
                    <img :src="require('@/assets/img/post-office-counter.png')" v-if="basketstore.consignmentType === 'DROP_AT_COUNTER'" />
                    <img :src="require('@/assets/img/collection.png')" v-if="basketstore.consignmentType === 'COLLECTION'" />
                </div>
                <div class="order-sidebar.consignment.content">
                    <span v-if="basketstore.consignmentType === 'DROP_AT_POSTBOX'" class="order-sidebar.consignment.text">You have chosen to drop your items at a postbox</span>
                    <span v-if="basketstore.consignmentType === 'DROP_AT_COUNTER'" class="order-sidebar.consignment.text">You have chosen to drop your items at an Isle of Man Post Office counter</span>
                    <span v-if="basketstore.consignmentType === 'COLLECTION'" class="order-sidebar.consignment.text">You have chosen to have your items collected from your home address</span>
                </div>
            </div>
            <div class="order-sidebar.summary.data">
                <p>Destination: <span class="order-sidebar.summary.data.value">{{ mainstore.selected.destination?.name }}</span></p>
                <p v-if="mainstore.selected?.weight < 1000">Weight: <span class="order-sidebar.summary.data.value">{{ mainstore.selected?.weight }}g ({{mainstore.selected?.weight/1000}}kg)</span></p>
                <p v-else>Weight: <span class="order-sidebar.summary.data.value">{{ mainstore.selected?.weight/1000 }}kg ({{mainstore.selected?.weight}}g)</span></p>
            </div>
            <div class="order-summary.product-card --sidebar parcel-size-animation" v-if="mainstore.selected.parcelSize.name">
                <h3 class="order-summary.product-card.title">{{ formatDisplayValue(mainstore.selected.parcelSize?.name) }}</h3>
                <img class="order-summary.product-card.image" v-if="mainstore.selected.parcelSize.image" :src="require('@/assets/img/' + mainstore.selected.parcelSize?.image)"/>
                <span class="order-summary.product-card.details">
                    <span class="order-summary.product-card.details.weight" v-if="mainstore.selected.parcelSize?.id == 'largeparcel' || mainstore.selected.parcelSize?.id == 'smallparcel'">Up to: <b>{{ mainstore.selected.parcelSize.maxWeight/1000 }}kg</b></span>

                    <span class="order-summary.product-card.details.weight" v-if="mainstore.selected.parcelSize?.id == 'letter' || mainstore.selected.parcelSize?.id == 'largeletter'">Up to: <b>{{ mainstore.selected.parcelSize.maxWeight }}g</b></span>

                    <span class="order-summary.product-card.details.size" v-if="mainstore.selected.parcelSize?.size">Max-size: <b>{{ mainstore.selected.parcelSize?.size }}</b></span>
                </span>
            </div>
            <div class="order-sidebar.summary.data"  v-if="this.$route.name != 'parcelSize' && this.$route.name != 'provideItems' && mainstore.selected.serviceDetails.length !== 0">
                <p v-if="mainstore.selected.serviceDetails.expandedName">Service: <span class="order-sidebar.summary.data.value">{{ formatDisplayValue(mainstore.selected.serviceDetails?.expandedName) }}</span></p>
                <p v-if="mainstore.selected.additionalInsurance && mainstore.selected.additionalInsurance.value !== null">Insured up to: <span class="order-sidebar.summary.data.value">£{{ mainstore.selected.additionalInsurance.value/100 }}</span></p>
            </div>
            <div class="order-summary.destination --sidebar" v-if="mainstore.selected.recipientDetails.firstName !== ''">
                <h2 class="order-summary.destination.title">Sending to:</h2>
                <p>{{ mainstore.recipientFullName }}</p>
                <span class="order-summary.destination.address">
                    <p v-if="mainstore.selected.recipientDetails.address.RECIPIENTCOMPANY">{{ mainstore.selected.recipientDetails.address.RECIPIENTCOMPANY }}</p>
                    <p v-if="mainstore.selected.recipientDetails.address.RECIPIENTADDRESSLINE1">{{ mainstore.selected.recipientDetails.address.RECIPIENTADDRESSLINE1 }}</p>
                    <p v-if="mainstore.selected.recipientDetails.address.RECIPIENTADDRESSLINE2">{{ mainstore.selected.recipientDetails.address.RECIPIENTADDRESSLINE2 }}</p>
                    <p v-if="mainstore.selected.recipientDetails.address.RECIPIENTADDRESSLINE3">{{ mainstore.selected.recipientDetails.address.RECIPIENTADDRESSLINE3 }}</p>
                    <p v-if="mainstore.selected.recipientDetails.address.RECIPIENTCITY">{{ mainstore.selected.recipientDetails.address.RECIPIENTCITY }}</p>
                    <p v-if="mainstore.selected.recipientDetails.address.RECIPIENTCOUNTY">{{ mainstore.selected.recipientDetails.address.RECIPIENTCOUNTY }}</p>
                    <p v-if="mainstore.selected.recipientDetails.address.RECIPIENTCOUNTRY">{{ mainstore.selected.recipientDetails.address.RECIPIENTCOUNTRY }}</p>
                    <p>{{ mainstore.selected.recipientDetails.address.RECIPIENTPOSTCODE }}</p>
                    <!--
                        <div v-for="value in Object.values(mainstore.selected.recipientDetails?.address)" :key="value">
                            {{ value }}
                        </div>
                    -->
                </span>
            </div>
            <div class="order-summary.sending-from --sidebar" v-if="Object.keys(this.collectionstore.collectionData.collectionAddress).length !== 0">
                <h2 class="order-summary.sending-from.title">Sending from:</h2>
                <p>{{ mainstore.sendersFullName }}</p>
                <span class="order-summary.sending-from.address">
                    <p v-if="collectionstore.collectionData.collectionAddress.address.ORG">
                        {{ capitalizeWords(collectionstore.collectionData.collectionAddress.address.ORG) }}
                    </p>
                    <p v-if="collectionstore.collectionData.collectionAddress.address.ADDRESSLINE1">
                        {{ capitalizeWords(collectionstore.collectionData.collectionAddress.address.ADDRESSLINE1) }}
                    </p>
                    <p v-if="collectionstore.collectionData.collectionAddress.address.ADDRESSLINE2">
                        {{ capitalizeWords(collectionstore.collectionData.collectionAddress.address.ADDRESSLINE2) }}
                    </p>
                    <p v-if="collectionstore.collectionData.collectionAddress.address.ADDRESSLINE3">
                        {{ capitalizeWords(collectionstore.collectionData.collectionAddress.address.ADDRESSLINE3) }}
                    </p>
                    
                    <p v-if="collectionstore.collectionData.collectionAddress.address.CITY">
                        {{ capitalizeWords(collectionstore.collectionData.collectionAddress.address.CITY) }}
                    </p>
                    <p v-if="collectionstore.collectionData.collectionAddress.address.POSTCODE">
                        {{ collectionstore.collectionData.collectionAddress.address.POSTCODE }}
                    </p>
                </span>
                <span v-if="this.collectionstore.collectionData.methodOfSupply === 'collection'" class="order-sidebar.summary.collection-info">Your items will be collected from this address <span v-if="this.collectionstore.collectionData.collectionDate">on {{ formatDate(this.collectionstore.collectionData.collectionDate) }}</span></span>
            </div>

        </div>
    </aside>

    <div v-if="showModal" class="confirmation-modal">
            <div class="confirmation-modal.panel">
                <span class="confirmation-modal.panel.close" @click="closeConfirmationModal"></span>
                <div class="confirmation-modal.panel.content">
                    <span class="confirmation-modal.panel.content.intro">Warning!</span>
                    <span class="confirmation-modal.panel.content.title">This action will delete all data you have entered including any items in your basket</span>
                    <span class="confirmation-modal.panel.content.text">Do you wish to continue?</span>
                    <div class="confirmation-modal.panel.content.buttons">
                        <TertiaryButton btntxt="Yes please" @click="continueToRoute()"/>
                        <MainButton btntxt="No thanks" @continue="closeConfirmationModal()"/>
                    </div>
                </div>
            </div>
        </div>
</template>

<script>
    import { useMainStore } from '@/stores/MainStore.ts';
    import { useCollectionStore } from '@/stores/CollectionStore.ts';
    import { useBasketStore } from '@/stores/BasketStore.ts';
    import anime from 'animejs';
    import TertiaryButton from '@/components/TertiaryButton.vue';
    import MainButton from '@/components/MainButton.vue';

    export default {
        name: 'OrderSidebar',

        setup() {
            const mainstore = useMainStore()
            const collectionstore = useCollectionStore()
            const basketstore = useBasketStore()
            return { mainstore, collectionstore, basketstore }
        },

        data() {
            return {
                showModal: false
            }
        },

        components: {
            MainButton,
            TertiaryButton
        },

        computed: {
            parcelSize() {
                return this.mainstore.selected.parcelSize.name
            }
        },

        watch: {
            parcelSize() {
                this.animateItems();
            }
        },

        methods: {
            animateItems() {
                anime({
                    targets: '.parcel-size-animation',
                    translateY: [100, 0],
                    opacity: [0, 1],
                    duration: 1000,
                    easing: 'easeOutExpo'
                });
            },

            capitalizeWords(str) {
                let words = str.toLowerCase().split(" ");
                for (let i = 0; i < words.length; i++) {
                    words[i] = words[i][0].toUpperCase() + words[i].slice(1);
                }
                return words.join(" ");
            },
            formatDate(date) {
                const options = {
                    weekday: "long",
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                };
                const formattedDate = new Date(date).toLocaleDateString("en-GB", options);
                return formattedDate;
            },

            resetAll() {
                window.resetLocalStorage();
                this.mainstore.fullReset();
                this.collectionstore.resetCollection();
                this.basketstore.resetBasket();
            },

            handleClick() {
                this.showModal = true;
            },

            closeConfirmationModal() {
                this.showModal = false;
            },

            continueToRoute() {
                this.resetAll();
                this.$router.push('/');
            },
            // TODO: Remove with pricing engine
            formatDisplayValue(value) {
                if (value == null) {
                    return value;
                }
                if (value.includes("small parcel")) {
                    return value.replace("small parcel", "parcel");
                } else if (value.includes("Small parcel")) {
                    return value.replace("Small parcel", "Parcel");
                } else if (value.includes("Small Parcel")) {
                    return value.replace("Small Parcel", "Parcel");
                }
                return value;
            }
        }
    }
</script>