<template>
    <PageTitle title="Cookie Policy"/>
    <section class="container">
        <div class="content rich-text-editor">
            <h2>Cookie Policy</h2>
            <p>This site uses cookies to create the most secure and effective website possible and to improve the visitor experience. By continuing to use this site, you agree to the use of cookies.</p>
            <p>To comply with the Privacy and Electronic Communications Regulations we periodically conduct a Cookie Audit of our website. This Cookie Audit was last updated on 9th August 2024.</p>
            <p>Our website uses a number of cookies to store data on our visitor's computers. Apart from necessary cookies which are crucial for website functionality and security, you are able to <b>Accept</b> or <b>Decline</b> the non-necessary cookies.</p>
            <p>For more information about managing cookies, please see <a href="http://www.aboutcookies.org/">http://www.aboutcookies.org/</a>.</p>

            <h3>Necessary Cookies</h3>
            <table>
                <thead>
                    <tr><th style="width: 20%">Cookie Name</th><th style="width: 20%">Expiry</th><th>Purpose</th></tr>
                </thead>
                <tbody>
                    <tr><td style="width: 20%">__cf_bm</td><td style="width: 20%">30 Minutes</td><td>Used by our payment processor - Worldpay</td></tr>
                    <tr><td style="width: 20%">__cfruid</td><td style="width: 20%">Session</td><td>Used by our payment processor - Worldpay</td></tr>
                </tbody>
            </table>

            <h3>Google Analytics</h3>
            <p>We use Google Analytics, which creates a number of first-party cookies, all prefixed _ga:</p>
            <table>
                <thead>
                    <tr><th style="width: 20%">Cookie Name</th><th style="width: 20%">Expiry</th><th>Purpose</th></tr>
                </thead>
                <tbody>
                    <tr><td style="width: 20%">_ga_GXSH5KMV3F</td><td style="width: 20%">400 days</td><td>Used by Google Analytics</td></tr>
                    <tr><td style="width: 20%">_ga</td><td style="width: 20%">400 days</td><td>Used by Google Analytics</td></tr>
                </tbody>
            </table>
            <p>Full details of the cookies which Google Analytics uses can be found in <a href="https://developers.google.com/analytics/resources/concepts/gaConceptsCookies">Cookies & Google Analytics</a> in documentation for Google's Analytics tracking code. You can also read <a href="http://www.google.com/analytics/learn/privacy.html">Google's Privacy Policy for Google Analytics</a>.</p>
            <p>We don't share the data that Google Analytics collects and the data collected is anonymous aggregate data which is used in the following ways:</p>
            <ul>
                <li>to report, in aggregate, how our visitors find and use our website;</li>
                <li>to understand overall visitor behaviour; and</li>
                <li>to inform how we update and improve our website.</li>
            </ul>

            <h2>Other Cookies</h2>
            <p>From time to time we may use additional external services on our website which may create cookies on your device. As we add new services or make changes that use cookies, we will update this cookie policy with the details.</p>
            <p>If you have any concerns about the cookies we create or the way we aim to attain consent from you, please <a href="https://www.iompost.com/contact/">contact us</a> and we'll be happy to answer your questions.</p>
        </div>
    </section>
</template>

<script>
    import PageTitle from '@/components/PageTitle.vue';

    export default {
        name: 'CookiePolicy',

        components: {
            PageTitle
        }
    }
</script>