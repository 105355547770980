<template>
    <button class="main-button" @click="$emit('continue')">
      <span class="main-button.text">{{ btntxt }}</span>
    </button>
</template>

<script>
export default {
  name: 'MainButton',
  
  props: {
    btntxt: String
  },

  emits: ["continue"],
}
</script>