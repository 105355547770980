<template>
    <div :class="{ 'loader-icon': true, '--dark': colour === 'dark' }"></div>
</template>

<script>
    export default {
        name: 'LoaderIcon',
        props: {
            colour: String // Assuming the prop type is String
        }
    }
</script>