<template>
    <PageTitle title="Labelling your items"/>
    <section class="container">
        <div class="content rich-text-editor">
            <p>Online Postage is designed to offer flexibility and convenience, allowing you to manage your postal requirements when it suits you 24 hours a day. The ability to print postage labels at home and take items to a post box or other locations operating outside of normal office hours provides further convenience.</p>
            <p>As you are printing your postage labels at home, the following guidance will be helpful.</p>
            <ul class="image-list">
                <li class="image-list.item"><span class="image-list.item.image"></span><span class="image-list.item.text">Ensure that your printer is in operational order and ready to print in black or dark blue. This  enables digital scanning and reading of address data and barcodes by automated sorting equipment</span></li>
                <li class="image-list.item"><span class="image-list.item.image --one-surface"></span><span class="image-list.item.text">Please ensure each printed label is secured to one surface of the correct item</span></li>
                <li class="image-list.item"><span class="image-list.item.image --obscured"></span><span class="image-list.item.text">Avoid covering any of the label detail with opaque adhesive tape</span></li>
                <li class="image-list.item"><span class="image-list.item.image --label-cut"></span><span class="image-list.item.text">The labels are 4”X6” (100mm X 150mm); if you are printing on A4 paper you will need to trim the paper down to size</span></li>
                <li class="image-list.item"><span class="image-list.item.image --tape"></span><span class="image-list.item.text">Make sure your labels are securely attached and will remain so for their journey through to their destination</span></li>
            </ul>
        </div>
    </section>
</template>

<script>
    import PageTitle from '@/components/PageTitle.vue';

    export default {
        name: 'LabelsContent',

        components: {
            PageTitle
        }
    }
</script>