<template>

  <div class="base-accordion" @click="toggle">
    <h2 class="base-accordion.title">
      <span class="base-accordion.title.number">
        <span class='base-accordion.title.number.content'>
          <slot name="number"></slot>
        </span>
      </span>
      <span class="base-accordion.title.text">
        <slot name="title"></slot>
      </span>
    </h2>
  </div>

    <div v-show="show" class="base-accordion.content">
      <slot name="content"></slot>
    </div>


</template> 

<script>
    export default {
        name: 'BaseAccordion',

        data() {
            return {
                show: false,
            };
        },

        methods: {
            toggle() {
                this.show = !this.show;
            },
        }
    }
</script>