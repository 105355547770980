<template>
    <PageTitle title="Parcel Postboxes" background="--parcel-postboxes"/>
    <section class="container">
        <div class="content rich-text-editor">
            <h2>What are they?</h2>
            <p>There are a number of parcel postboxes in locations in and around the Douglas area to enable the fast and convenient postage of parcels.</p>
            <p>If you are buying your postage online, you can now drop your item off at one of these parcel postboxes. </p>

            <h2>Specifications</h2>
            <p>Max size of parcel: 44cm x 35cm x 16cm</p>

            <h2>Sending an item</h2>
            <ul>
                <li>Buy your postage using Online Postage</li>
                <li>Print your labels</li>
                <li>Affix the labels to your item</li>
                <li>Drop into a parcel postbox.</li>
            </ul>
            <h2>Restrictions</h2>
            <p>Parcel postboxes are only available for prepaid items.</p>
            <p>For Special Delivery Guaranteed and Tracked items, please visit a Post Office counter. We cannot accept these items in a parcel postbox as there will be no proof of receipt.</p>
            <h2>What to do if the postbox is full</h2>
            <p>If you arrive to find a parcel postbox is full, please contact customer services on <b>01624 664664</b></p>
            <p>If full we advise that you drop your item off at nearest alternative postbox or Post Office counter.</p>
            <h2>Parcel postbox locations</h2>
            <p>Please see the <a href="https://www.iompost.com/tools-forms/post-office-services-finder/" target="_blank">interactive map</a> on our website</p>
        </div>
    </section>
</template>

<script>
    import PageTitle from '@/components/PageTitle.vue';

    export default {
        name: 'PostboxContent',

        components: {
            PageTitle
        }
    }
</script>