<template>
    <div class="confirmation-error">
        <div class="content">
            <div class="page-error">
                <span class="page-error.intro">Sorry, the system has encountered an error</span>
                <span class="page-error.advice">Please contact customer support on: <span>664664</span></span>
                <span class="page-error.advice">Reference: {{ shortenRef(this.basketStore.consignmentData.consignmentRef) }}</span>
                <div class="page-error.button">
                    <router-link to="/" class="main-button"><span class="main-button.text">Back to homepage</span></router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { useBasketStore } from '@/stores/BasketStore.ts';

    export default {
        setup() {
            const basketStore = useBasketStore();

            return { 
                basketStore,
            }
        },

        name: 'ConfirmationError',

        methods: {
            shortenRef(value) {
                return value.slice(0, 10).toUpperCase();
            }
        }
    }

</script>

<style scoped>
.confirmation-error {
    margin-top: 60px;
}
</style>