<template>
  <SkipLinks/>
  <SiteNavigation/>
  <form @submit.prevent="onSubmit" autocomplete="off">
    <router-view></router-view>
  </form>
  <PostageProcess v-show="this.$route.name == 'home'"/>
  <PostageOptions v-show="this.$route.name == 'home'"/>
  <!--

    <div class="container background-picture --safe-to-fly" v-show="this.$route.name == 'home'">
      <SafeflyCard/>
    </div>

  -->
  <FrequentQuestions/>
  <SiteFooter/>
  <SystemDowntime @accepted="closeModal"/>
  <CookiePrompt @accepted="closeModal" />
  <InterstitialLoader v-if="mainstore.pageLoading === true"/>
</template>

<script>
  import SkipLinks from './components/SkipLinks.vue';
  import SiteNavigation from './components/SiteNavigation.vue';
  //import GetStarted from './components/GetStarted.vue';
  import PostageProcess from './components/PostageProcess.vue';
  import PostageOptions from './components/PostageOptions.vue';
  import FrequentQuestions from './components/FrequentQuestions.vue';
  //import SafeflyCard from './components/SafeflyCard.vue';
  import SiteFooter from './components/SiteFooter.vue';
  import SystemDowntime from './components/SystemDowntime.vue';
  import CookiePrompt from './components/CookiePrompt.vue'
  import InterstitialLoader from './components/InterstitialLoader.vue';
  import { useMainStore } from '@/stores/MainStore.ts';


  export default {

    setup() {
      const mainstore = useMainStore();

      return { 
        mainstore,
      }
    },

    components: {
        SiteNavigation,
        //GetStarted,
        SkipLinks,
        FrequentQuestions,
        SiteFooter,
        PostageProcess,
        PostageOptions,
        //SafeflyCard,
        SystemDowntime,
        CookiePrompt,
        InterstitialLoader
      },

    data() {
      return {
        popoverActive: false
      };
    },

    watch: {
      popoverActive: function(value) {
        if (value === true) {
          document.body.classList.add("modal-open");
        } else if (value === false) {
          document.body.classList.remove("modal-open");
        }
      }
    },

    methods: {
      closeModal() {
        this.popoverActive = false;
      }

    }

  }
</script>