<template>
    <PageTitle/>
    <section class="container">
        <div class="content rich-text-editor">
          <p>Isle of Man Post Office's “Online Postage” is a simple and convenient way to purchase postage online with, or without access to a printer and without the need to setup an account.</p>
          <p>It only takes a couple of minutes to complete your transaction and you can choose to either drop your items at one of our locations, or into a post box. Collections are also available so you don't even need to leave home to post!</p> 
           <h2>Using Online Postage</h2>
           <h3>Do I need to register for an account?</h3>
            <p>There is no registration requirement to use Online Postage.</p>
            <h3>How can I access Online Postage?</h3>
            <p>You can use Online Postage on desktop computers, tablets or mobile devices.</p>
            <h3>What do I need to use Online Postage?</h3>
            <p>You will need to be able to weigh and measure your item accurately to ensure the correct service and postage fee is purchased. Thorough checks are performed on all Online Postage items, and if there is insufficient postage applied due to incorrect size or weights, you will be contacted and the items will be detained until full payment is collected. If not paid within 5 working days, you will need to purchase your postage again.</p>
            <h3>Which postal services are available to me?</h3>
            <p>You will have access to all postal services usually available from a counter, including services not available at counters such as Special Delivery - Next Day. The services available to you will be appear as you move through your transaction. The choice of services offered to you is based on:</p>
            <ul>
                <li>The weight of the item</li>
                <li>The dimensions of the item</li>
                <li>The intended destination of the item</li>
                <li>How your item will enter our network e.g. post box or collection</li>
            </ul>
            <h3>Is there a limit to the number of items I can send using Online Postage?</h3>
            <p>You are able to purchase postage for up to 10 items in a single transaction, however you can perform as many transactions as you wish. If you are regularly sending higher volumes of items, then you may wish to consider using our Click & Dispatch service - contact the Commercial Team by email at sales@iompost.com</p>
            <h3>Can I get insurance for loss or damage to my item?</h3>
            <p>Insurance is available on a number of services and varies in level. This will be outlined to you when proceeding through the transaction.</p>
            <h3>How do I pay for postal services on Online Postage?</h3>
            <p>You will be required to pay via secure card payment before accessing your postage label.</p>
            <h3>Will I get a receipt?</h3>
            <p>A receipt containing a list of all the items you have purchased will be emailed to you automatically after you have completed your purchase.</p>
            <h3>Can I get a VAT receipt?</h3>
            <p>You can select an option to receive a VAT receipt should you require one.</p>
            <h2>Printing Labels</h2>
            <h3>Can I print labels at a Post Office location?</h3>
            <p>If you do not have access to a printer, you can request a collection from your home address. The post person collecting your item will bring your label with them.</p>
            <h3>What size label should I use?</h3>
            <p>Online Postage labels are 4”x6” (100mm x 150mm) and designed to be printed onto standard A4 paper used in most printers. You will need to trim your labels to size before attaching them to your items. Please see our <router-link to="/labels">packing advice</router-link> for further information.</p>
            <h3>What do I do if I have problems printing my label?</h3>
            <p>If you have any issues printing your label, you can contact the Customer Services team, or call in to the counter at IOMPO Headquarters, where staff will be able to verify your order and print your label for you.</p>
            <h2>Safe to Post requirements</h2>
            <h3>How do I know if my item is Safe to Post?</h3>
            <p>Many of our everyday items are now considered dangerous goods under transportation legislation. It is your responsibility to research if the items you plan to send are prohibited or restricted. Documentation explaining which items are safe to post can be found <a href="https://www.iompost.com/for-you/sending-mail/preparing-to-send-your-mail/prohibited-and-restricted-items/" target="_blank">here</a></p>
            <p>You will need to confirm your item is "Safe to Post" when finalising your Online Postage purchase. False declaration will result in your item being held and possibly destroyed.</p>
            <h2>Customs requirements</h2>
            <h3>What are customs requirements?</h3>
            <p>If you are sending items other than documents beyond the UK you will need to complete the customs section in Online Postage which you will be guided through. You will be required to provide a brief description of each individual item within the package including the quantity (the individual quantities of each item and the total quantity of items), net weight and unit of measurement (e.g kilograms) and value of items in GBP.</p>
            <h3>What will happen to my item if I don't provide a customs declaration?</h3>
            <p> Failure to comply with customs requirements will result in your item being held and possibly disposed of. It is important that your customs label includes all the correct information and is securely attached to your item.</p>
            <h2>Drop off locations</h2>
            <h3>Where can I drop off my item(s)?</h3>
            <p>We have post boxes and parcel boxes located across the Island, and you can also visit any of our counter facilities including parcel collection points. For more information please see our <a href="https://www.iompost.com/tools-forms/post-office-services-finder/">interactive map</a> on the website.</p>
            <h2>Collections</h2>
            <h3>How do I arrange a collection?</h3>
            <p>You can select to arrange a collection during the purchase process. You will see options for the next three working days, starting from the day after the present day. Collections are not currently available on weekends or bank holidays. <router-link to="/termsandconditions">Terms and Conditions</router-link> apply.</p>
            <h3>What do I do if I miss my collection?</h3>
            <p>If your item is not available when we attempt collection, we will deliver your labels through your letterbox. You will then have the option to take your item to one of our counter facilities prior to the expiry date shown on the label. There are no repeat attempts to perform collections. To avoid a missed collection, please provide a safe collection place when requested to do so, should you believe you will not be at home when the collection will take place.</p>
            <h2>Contacts</h2>
            <h3>Who do I contact should I have a problem using Online Postage?</h3>
            <p>Please contact us via the dedicated phone number 664664.</p>
            <h3>How will you manage my data?</h3>
            <p>Please see <router-link to="/privacypolicy">Privacy Notice</router-link></p>
        </div>
    </section>
</template>

<script>
    import PageTitle from '@/components/PageTitle.vue';

    export default {
        name: 'FaqContent',

        components: {
            PageTitle
        }
    }
</script>