<template>
    <fieldset class="customs-form" style="margin-top: 80px;">
        <div class="container">
            <h2 class="customs-form.title">Please select the fields relevant to you:</h2>
            <CustomsCheckboxes/>
            <div v-if="mainstore.selected.sendingGoods === 'goods'">
                <h2 class="customs-form.title">Your items</h2>
                <span class="customs-form.intro">Please complete for each individual item you are sending. Customs data is an international requirement for sending goods beyond the UK</span>
                <div v-for="(customsItem, index) in mainstore.selected.customsItems" :key="customsItem.id">
                    <CustomsItem
                        :item="customsItem"
                        :itemIndex="index"
                        :firstItem="firstItem"
                        @add-values="totalValues()"
                        @delete-item="deleteCustomsItem(index)"
                        @clear-item="checkCustomsValidations()"
                        @change="checkCustomsValidations()"
                    />
                </div>
                <button class="secondary-button" style="margin-bottom: 60px;" @click="increment(), addItem()">Add another item</button>
                <BusinessFields v-if="mainstore.selected.vatRegistered === true" @valid="setBusinessValidation" @invalid="setBusinessInvalidation"/>
                <CategoryField @change="checkCustomsValidations()"/>
                <CustomsTotals @change="checkCustomsValidations()" @valid="setBusinessValidation()" @invalid="setBusinessInvalidation()"/>
            </div>
        </div>
    </fieldset>
</template>

<script>
import { useMainStore } from '@/stores/MainStore.ts';
import CustomsItem from './CustomsItem.vue';
import BusinessFields from './BusinessFields.vue';
import CategoryField from './CategoryField.vue';
import CustomsTotals from './CustomsTotals.vue';
import CustomsCheckboxes from './form-components/CustomsCheckboxes.vue';

export default {
    setup() {
        const mainstore = useMainStore();
        return { mainstore };
    },

    name: 'CustomsForm',
    components: {
        CustomsItem,
        BusinessFields,
        CategoryField,
        CustomsTotals,
        CustomsCheckboxes,
    },

    data() {
        return {
            counter: 0,
            totalPrice: 0,
            firstItem: null,
            businessFieldsValid: null
        };
    },

    methods: {
        checkCustomsValidations() {
            const customsItemsValid = this.mainstore.selected.customsItems.every(item => {
                return (
                    item.itemLines.ITEMDESCRIPTION &&
                    item.itemLines.COUNTRYOFORIGIN &&
                    item.itemLines.TARIFFCODE &&
                    item.itemLines.ITEMQUANTITY !== null &&
                    item.itemLines.NETWEIGHT !== null &&
                    item.itemLines.ITEMVALUE !== null
                );
            });

            const categoryValid = !!this.mainstore.selected.category;
            const weightValid = this.mainstore.selected.weight >= (this.mainstore.customsTotalWeight * 1000);
            const businessFieldsValid = this.mainstore.selected.vatRegistered ? this.businessFieldsValid : true;
            const overallValid = customsItemsValid && categoryValid && weightValid && businessFieldsValid;

            this.mainstore.validations.customsValid = overallValid;
        },

        setBusinessValidation() {
            this.businessFieldsValid = true;
            this.checkCustomsValidations();
        },

        setBusinessInvalidation() {
            this.businessFieldsValid = false;
            this.checkCustomsValidations();
        },

        increment() {
            this.counter++;
        },

        addItem() {
            this.mainstore.selected.customsItems.push({
                itemLines: {
                    PACKAGEID: this.counter,
                    TARIFFCODE: '',
                    TARIFFDESCRIPTION: '',
                    ITEMDESCRIPTION: '',
                    ITEMQUANTITY: null,
                    ITEMVALUE: null,
                    COUNTRYOFORIGIN: '',
                    NETWEIGHT: null
                },
            });
            this.firstItem = false;
            this.checkCustomsValidations();
        },

        totalValues() {
            let sum = 0;
            this.mainstore.selected.customsItems.forEach(element => {
                sum += element.itemLines.ITEMVALUE;
            });
            this.totalPrice = sum;
            this.checkCustomsValidations();
        },

        deleteCustomsItem(index) {
            this.mainstore.selected.customsItems.splice(index, 1);
            if (this.mainstore.selected.customsItems.length === 0) {
                this.addItem();
            }
            if (this.mainstore.selected.customsItems.length === 1) {
                this.firstItem = true;
            }
            this.checkCustomsValidations();
        },
    },

    watch: {
        businessFieldsValid() {
            this.checkCustomsValidations();
        }
    },

    mounted() {
        if (this.mainstore.selected.customsItems.length === 1) {
            this.firstItem = true;
        }
        this.checkCustomsValidations();
    }
};
</script>


