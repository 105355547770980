<template>
    <fieldset class="category-field">
        <label for="category" class="form-label --dark">Category of items<b class="form-label.required">*</b></label>
        <select 
            name="category"
            id="category" 
            class="form-select-field --dark"
            v-model="this.mainstore.selected.category"
            @change="validateCategory()"
            >
            <option value="" disabled selected>Please choose &hellip;</option>
            <option
                v-for="option in currentOptions"
                :key="option.value"
                v-bind:value="option.value"
            >
            {{ option.text }}
            </option>
        </select>
        <p class="error" v-if="categoryValid === false">You must select a category</p>
    </fieldset>
</template>

<script>
    import { useMainStore } from '@/stores/MainStore.ts';

    export default {
        setup() {
            const mainstore = useMainStore()
            return {
                mainstore
            }
        },

        name: 'CategoryField',

        data() {
            return {
                form: {
                    category: ''
                },
                options:
                [
                    { value: 'Gift', text: 'Gift' },
                    { value: 'Commercial Sample', text: 'Commercial Sample' },
                    { value: 'Documents', text: 'Documents' },
                    { value: 'Return of Goods', text: 'Return of Goods' },
                    { value: 'Sale of Goods', text: 'Sale of Goods' },
                    { value: 'Mixed Content', text: 'Mixed Content' },
                    { value: 'Other', text: 'Other' }
                ],
                optionsPF: [
                    { value: 'Gift', text: 'Gift' },
                    { value: 'Sold', text: 'Sold' },
                    { value: 'Not Sold', text: 'Not Sold' },
                    { value: 'Personal Effects', text: 'Personal Effects' },
                    { value: 'Sample', text: 'Sample' },
                ],
                categoryValid: null
            }
        },

        computed: {
            currentOptions() {
                return this.mainstore.selected.serviceDetails.controlCode === 'PF' ? this.optionsPF : this.options;
            }
        },

        methods: {
            validateCategory() {
                let data = this.mainstore.selected.category;
                if(data === '' || data === null) {
                    this.categoryValid = false;
                } else {
                    this.categoryValid = true;
                }
            },
        }
    }
</script>