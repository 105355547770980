<template>
    <!--<PageTitle title="Order Summary" background="--summary"/>-->
    <section class="order-summary container">
        <div class="order-summary.entered-values" v-if="mainstore.selected.destination">
            <div class="order-summary.entered-values.destination-country">
                <span>Destination: <span class="order-summary.entered-values.destination-country.data">{{ mainstore.selected.destination.name }}</span></span>
            </div>
            <div class="order-summary.entered-values.weight" v-if="mainstore.selected.weight"><span>Weight: <span class="order-summary.entered-values.weight.data">{{ mainstore.selected.weight }}  Grams</span></span> ({{ mainstore.selected.weight/1000 }}KG)</div>
        </div>
        <div class="order-summary.product-card">
            <h3 class="order-summary.product-card.title">{{ formatDisplayValue(mainstore.selected.parcelSize.name) }}</h3>
            <img class="order-summary.product-card.image" :src="require('@/assets/img/' + mainstore.selected.parcelSize.image)"/>
            <span class="order-summary.product-card.details">
                <span class="order-summary.product-card.details.weight">Up to: <b>{{ mainstore.selected.parcelSize.maxWeight }}g</b></span>
                <span class="order-summary.product-card.details.size">Max-size: <b>{{ mainstore.selected.parcelSize.size }}</b></span>
            </span>
        </div>
        <div class="order-summary.service" v-if="mainstore.selected.service">
            <span class="order-summary.service.text">{{ formatDisplayValue(mainstore.selected.serviceDetails.expandedName) }}</span>
        </div>
        <div class="order-summary.insurance" v-if="mainstore.selected.insurance">
            <span class="order-summary.insurance.text">Insured to £{{ mainstore.selected.insurance }}</span>
        </div>
        <div class="order-summary.destination" v-if="mainstore.selected.recipientDetails.firstName">
            <h2 class="order-summary.destination.title">Sending to:</h2>
            <p>{{ mainstore.recipientFullName }}</p>
            <p>{{ mainstore.selected.recipientDetails.businessName }}</p>
            <span class="order-summary.destination.address">
                <!--
                    <div v-for="value in Object.values(mainstore.selected.recipientDetails.address)" :key="value">
                        {{ value }}
                    </div>
                    <p>{{ mainstore.selected.destination.name }}</p>
                -->
                <span class="order-summary.destination.address">
                    <p v-if="mainstore.selected.recipientDetails.address.RECIPIENTCOMPANY">{{ mainstore.selected.recipientDetails.address.RECIPIENTCOMPANY }}</p>
                    <p v-if="mainstore.selected.recipientDetails.address.RECIPIENTADDRESSLINE1">{{ mainstore.selected.recipientDetails.address.RECIPIENTADDRESSLINE1 }}</p>
                    <p v-if="mainstore.selected.recipientDetails.address.RECIPIENTADDRESSLINE2">{{ mainstore.selected.recipientDetails.address.RECIPIENTADDRESSLINE2 }}</p>
                    <p v-if="mainstore.selected.recipientDetails.address.RECIPIENTADDRESSLINE3">{{ mainstore.selected.recipientDetails.address.RECIPIENTADDRESSLINE3 }}</p>
                    <p v-if="mainstore.selected.recipientDetails.address.RECIPIENTCITY">{{ mainstore.selected.recipientDetails.address.RECIPIENTCITY }}</p>
                    <p v-if="mainstore.selected.recipientDetails.address.RECIPIENTCOUNTY">{{ mainstore.selected.recipientDetails.address.RECIPIENTCOUNTY }}</p>
                    <p v-if="mainstore.selected.recipientDetails.address.RECIPIENTCOUNTRY">{{ mainstore.selected.recipientDetails.address.RECIPIENTCOUNTRY }}</p>
                    <p>{{ mainstore.selected.recipientDetails.address.RECIPIENTPOSTCODE }}</p>
                    <!--
                        <div v-for="value in Object.values(mainstore.selected.recipientDetails?.address)" :key="value">
                            {{ value }}
                        </div>
                    -->
                </span>
            </span>
        </div>
        <div class="order-summary.sending-from" v-if="mainstore.selected.sendersDetails.address.id">
            <h2 class="order-summary.sending-from.title">Sending from:</h2>
            <p>{{ mainstore.sendersFullName }}</p>
            <span class="order-summary.sending-from.address">
                <p v-if="mainstore.selected.sendersDetails.address.address.ADDRESSLINE1">
                    {{ capitalizeWords(mainstore.selected.sendersDetails.address.address.ADDRESSLINE1) }}
                </p>
                <p v-if="mainstore.selected.sendersDetails.address.address.ADDRESSLINE2">
                    {{ capitalizeWords(mainstore.selected.sendersDetails.address.address.ADDRESSLINE2) }}
                </p>
                <p v-if="mainstore.selected.sendersDetails.address.address.ADDRESSLINE3">
                    {{ capitalizeWords(mainstore.selected.sendersDetails.address.address.ADDRESSLINE3) }}
                </p>
                <p v-if="mainstore.selected.sendersDetails.address.address.CITY">
                    {{ capitalizeWords(mainstore.selected.sendersDetails.address.address.CITY) }}
                </p>
                <p>{{ mainstore.selected.sendersDetails.address.address.POSTCODE }}</p>
                <p>
                    Isle of Man
                </p>
            </span>
        </div>
        <div class="order-summary.total-price" v-if="mainstore.selected.service">
            <span class="order-summary.total-price.label">Total price:</span>
            <span class="order-summary.total-price.value">£{{ mainstore.totalItemPrice }}<span v-if="mainstore.selected.serviceDetails.taxCode" class="order-summary.total-price.value.tax"> Incl. VAT</span></span>
        </div>
        <!-- Commented out as user could put anything in here and have it displayed on a label i.e f**k y*u Royal Mail. Unless we can validate the input against expletives, I don't think we should include this.

            <div>
                <label class="form-label" for="reference">Would you like to add your own reference for this shipment? This reference will be displayed on your label (Optional)</label>
                <input class="form-text-field" type="text" name="reference" id="reference" maxlength="15" v-model="mainstore.selected.shipperRef" />
            </div>
        -->
    </section>
</template>

<script>
    import { useMainStore } from '@/stores/MainStore.ts';
    import { useBasketStore } from '@/stores/BasketStore.ts';
    //import PageTitle from '@/components/PageTitle';

    export default {
        name: 'OrderSummary',

        components: {
            //PageTitle
        },

        setup() {
            const mainstore = useMainStore()
            const basketstore = useBasketStore()
            return {
                mainstore,
                basketstore
            }
        },

        mounted() {
            this.mainstore.validations.orderSummaryValid = true
        },

        methods: {
            capitalizeWords(str) {
                let words = str.toLowerCase().split(" ");
                for (let i = 0; i < words.length; i++) {
                    words[i] = words[i][0].toUpperCase() + words[i].slice(1);
                }
                return words.join(" ");
            },
            // TODO: Remove with pricing engine
            formatDisplayValue(value) {
                if (value == null) {
                    return value;
                }
                if (value.includes("small parcel")) {
                    return value.replace("small parcel", "parcel");
                } else if (value.includes("Small parcel")) {
                    return value.replace("Small parcel", "Parcel");
                } else if (value.includes("Small Parcel")) {
                    return value.replace("Small Parcel", "Parcel");
                }
                return value;
            }
        }
    }
</script>