<template>
    <section class="postage-options">
        <h2 class="postage-options.title">Your postage options</h2>
        <!--
            <div class="postage-options.item --top-item">
            <img src="@/assets/img/print-from-home.png" class="postage-options.item.image" title="Print at home" alt="Illustration of a person printing a postage label at home" />
            <h3 class="postage-options.item.title">Print your label at home</h3>
            <span class="postage-options.item.text">Purchase your label(s), print and attach to your item.</span>
        </div>
        -->
        <div class="postage-options.desktop-container">
            <div class="postage-options.item">
                <img src="@/assets/img/collection.png" class="postage-options.item.image" title="Collection" alt="Illustration of a post office vehicle" />
                <h3 class="postage-options.item.title">Collection</h3>
                <span class="postage-options.item.text">Arrange to have your item(s) collected from your home.</span>
                <p class="postage-options.item.added-info">Collections available (fees may apply)</p>
                <p class="postage-options.item.added-info">Collection must be added to your item prior to completing your purchase</p>
            </div>
            <div class="postage-options.item">
                <img src="@/assets/img/parcel-postbox.png" class="postage-options.item.image" title="Parcel Postbox" alt="Illustration of a parcel postbox" />
                <h3 class="postage-options.item.title">Postbox</h3>
                <span class="postage-options.item.text">Print your label at home, affix it to your item and drop off at one of our postboxes. The locations are marked on <a href="https://www.iompost.com/tools-forms/post-office-services-finder/" target="_blank">this map</a>.</span>
                <!-- <p class="postage-options.item.added-info">Only choose this option if you are sending standard letter, large letter or small parcel and do not require proof of postage --><!--Please see below option for sending with proof of postage, tracked and signed services.</p> -->
                <p class="postage-options.item.added-info">Only choose this option if you are sending standard letter, large letter or parcel and do not require proof of postage <!--Please see below option for sending with proof of postage, tracked and signed services.--></p>

            </div>
            <div class="postage-options.item">
                <img src="@/assets/img/post-office-counter.png" class="postage-options.item.image" title="Post Office Counter" alt="Illustration of a post office counter" />
                <h3 class="postage-options.item.title">Post Office Counter</h3>
                <span class="postage-options.item.text">Affix your labels to your items and take them to an Isle of Man Post Office counter along with your pre-printed label(s). The locations are marked on <a href="https://www.iompost.com/tools-forms/post-office-services-finder/" target="_blank">this map</a>.</span>
                <p class="postage-options.item.added-info">Select this option if you require proof of postage, tracked or signed services.</p>
                <p class="postage-options.item.added-info">HM Forces free postage is only available from a Post Office Counter.</p>
            </div>
        </div>
    </section>
</template>

<script>
    export default {
        name: 'PostageOptions'
    }
</script>