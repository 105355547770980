import { defineStore } from 'pinia';
import { useStorage } from '@vueuse/core';

export const useBasketStore = defineStore('BasketStore', { 

  state: () => ({ 

    lastUpdated: useStorage('basketLastUpdated', null),

    basketItems: useStorage('basket', []),
    sessionState: '',
    sendersDetails: useStorage('sendersDetails', []),
    addressAdded: false,
    consignmentType: '',
    collectionIncluded: false,
    consignmentData: useStorage('consignment', {}),
    billingAddress: {
      addressLine1: '',
      addressLine2: '',
      addressLine3: '',
      city: '',
      county: '',
      country: null,
      postcode: ''
    }
  }),

  getters: {
    
    total: state => {
      return state.basketItems.reduce((acc, val) => {
        let itemPrice = val.serviceDetails.priceInPence;
        if (val.additionalInsurance && val.additionalInsurance.price) {
          itemPrice += val.additionalInsurance.price;
        }
        
        return acc + itemPrice;
      }, 0);
    },

    basketCount: state => {
      return state.basketItems.length
    },

    hasTaxCode() {
      return this.basketItems.some(item => item.serviceDetails?.taxCode === true);
    }

  },
  actions: {
    removeItem(item) {
      const index = this.basketItems.indexOf(item);
      if (index > -1) {
        this.basketItems.splice(index, 1);
      }
    },

    resetBasket() {
      this.lastUpdated = new Date(); // Time of the last update

      this.basketItems = [],
      this.sendersDetails = [],
      this.billingAddress = {
        addressLine1: '',
        addressLine2: '',
        addressLine3: '',
        city: '',
        county: '',
        country: null,
        postcode: ''
      },
      this.consignmentType = ''
    },

    isOlderThanMidnight() {
      if (!this.lastUpdated) return false;

      const now = new Date();
      const midnight = new Date(now.getFullYear(), now.getMonth(), now.getDate());
      return new Date(this.lastUpdated) < midnight;
    }

  }
})