<template>
  <div>
    <div v-if="showCookiePrompt" class="cookie-prompt">
      <div class="cookie-prompt.popover">
        <span class="cookie-prompt.popover.content.title">
          We use cookies
        </span>
        <span class="cookie-prompt.popover.content.info">
          These help us to improve our site and enable us to deliver the best possible service and customer experience.
          By clicking accept you are agreeing to our
          <router-link to="/cookiepolicy" ><span class="main-button.text">cookie policy.</span></router-link>
        </span>
        <button class="main-button" style="margin-bottom: 10px;" @click="acceptCookies">
          <span class="main-button.text">Accept</span>
        </button>
        <button class="secondary-button" @click="declineCookies">
          <span class="main-button.text">Decline</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CookiePrompt',

  data() {
    return {
      showCookiePrompt: true
    };
  },

  methods: {
    acceptCookies() {
      const head = document.getElementsByTagName("head")[0]
      let gaScript = document.createElement('script')
      gaScript.src = "https://www.googletagmanager.com/gtag/js?id=" + process.env.VUE_APP_ANALYTICS_KEY;
      
      gaScript.onload = function() {
        // eslint-disable-next-line
        window.dataLayer = window.dataLayer || [];
        // eslint-disable-next-line
        function gtag() { dataLayer.push(arguments); }
        gtag('js', new Date());
        gtag('config', process.env.VUE_APP_ANALYTICS_KEY);
      }
      head.insertBefore(gaScript, head.children[1]);

      window.localStorage.setItem("cookie_consent", "yes")
      this.showCookiePrompt = false;
    },
    declineCookies() {
      window.localStorage.setItem("cookie_consent", "no")
      this.showCookiePrompt = false;
    }

  },

  mounted() {
    const consent = window.localStorage.getItem("cookie_consent")
    if (consent && consent == "yes")
      this.acceptCookies() // inject scripts and close the prompt
    else if (consent && consent == "no")
      this.showCookiePrompt = false; // close prompt
    else 
      this.showCookiePrompt = true; // show consent prompt
  }
};
</script>