<template>
    <footer class="footer">
        <div class="footer.navigation">
            <img src="@/assets/online-postage-logo.svg" class="footer.navigation.logo"/>
            <router-link to="/termsandconditions" class="footer.navigation.link">Terms & Conditions</router-link>
            <router-link to="/privacypolicy" class="footer.navigation.link">Privacy Policy</router-link>
            <router-link to="/cookiepolicy" class="footer.navigation.link">Cookie Policy</router-link>
        </div>
        <div class="footer.copyright">
            <span class="footer.copyright.text">
                © Copyright {{ fullYear }} Isle of Man Post Office. <br/> All rights reserved
            </span>
        </div>
    </footer>
</template>

<script>
    export default {
        name: 'SiteFooter',

        data() {
            return {
                fullYear: new Date().getFullYear(),
            }
        }
    }
</script>