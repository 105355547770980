<template>
    <div class="container">
        <div class="not-found content">
            <h1 class="not-found.title">(404) Page not found</h1>
            <p class="not-found.text">Sorry, the page you are looking for could not be found</p>
            <router-link to="/" class="main-button"><span class="main-button.text">Go to homepage</span></router-link>
        </div>
    </div>
</template>

<script>
export default {
    name: 'NotFound'
}
</script>