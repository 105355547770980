<template>
  <div class="worldpay-checkout">
    <div v-if="redirectUri !== ''">
      <div id="custom-html"></div>
      <iframe id="iframe" src="" frameborder="0"></iframe>
    </div>
    <div class="worldpay-checkout.loader" v-else>
      <LoaderIcon />
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { useMainStore } from '@/stores/MainStore.ts';
import { useBasketStore } from '@/stores/BasketStore.ts';
import LoaderIcon from '@/components/form-components/LoaderIcon.vue';
//import FormTextField from '@/components/form-components/FormTextField';


export default {
  setup() {
    const mainstore = useMainStore();
    const basketstore = useBasketStore();

    return { 
        mainstore,
        basketstore
    }
  },

  name: 'WorldpayCheckout',

  components: {
    LoaderIcon,
    //FormTextField
  },

  data() {
    return {
      redirectUri: ""
    }
  },

  methods: {

    async getUri() {
      // let token = this.mainstore.tokens.xauthtoken;

      try {
        const response = await axios.post(
          process.env.VUE_APP_API_WORLDPAY,
          {
            orderCode: this.basketstore.consignmentData.consignmentRef,
            shopperEmailAddress: this.basketstore.basketItems[0].sendersDetails.emailAddress,
            billing: {
              address1: this.basketstore.billingAddress.addressLine1,
              address2: this.basketstore.billingAddress.addressLine2,
              address3: this.basketstore.billingAddress.addressLine3,
              postalCode: this.basketstore.billingAddress.postcode,
              city: this.basketstore.billingAddress.city, 
              state: this.basketstore.billingAddress.county,
              countryCode: this.basketstore.billingAddress.country.alpha2Code,
            }
          },
          {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': process.env.VUE_APP_AUTHORIZATION,
              //'X-Auth-Token': token
            }
          }
        );

        this.$data.redirectUri = response.data.paymentUrl;

      } catch (error) {
        console.error('An error occurred:', error);
      }
    },

    async wpCheckout() {
      const url = this.redirectUri.replace(/&amp;/g, "&")
      var customOptions = {
        type: 'iframe',
        iframeIntegrationId: 'libraryObject',
        iframeHelperURL: process.env.VUE_APP_BASE_URL + "/faq",
        iframeBaseURL: window.location.origin,
        inject: "immediate",
        url: url,
        target: 'custom-html',
        trigger: 'custom-trigger',
        lightboxMaskOpacity: 50,
        lightboxMaskColor: '#000000',
        lightboxShadowColor: '#666',
        lightboxShadowSize: '30px',
        accessibility: true,
        debug: false,
        language: 'en',
        country: 'gb',
        preferredPaymentMethod: '',
        successURL: process.env.VUE_APP_BASE_URL + '/confirmation',
        cancelURL: process.env.VUE_APP_BASE_URL + '/basket',
        failureURL: process.env.VUE_APP_BASE_URL +'/basket',
        //resultCallback: (result) => console.log('Callback result:', result),
        pendingURL: process.env.VUE_APP_BASE_URL + '/confirmation',
        errorURL: process.env.VUE_APP_BASE_URL + '/error'
      };
      // eslint-disable-next-line
      var libraryObject = new WPCL.Library();
      libraryObject.setup(customOptions);
    },
  },

  async mounted() {
    await this.getUri(); 
    this.wpCheckout();
  }

}
</script>