<template>
    <section class="postage-process">
        <h2 class="postage-process.title">How does Online Postage work?</h2>
        <ul class="postage-process.list">
            <li class="postage-process.list.item">
                <span class="postage-process.list.item.icon --form"></span>
                <span class="postage-process.list.item.text"><p class="postage-process.list.item.text.content">Get started by selecting the destination and weight.</p></span>
            </li>
            <li class="postage-process.list.item">
                <span class="postage-process.list.item.icon --form"></span>
                <span class="postage-process.list.item.text"><p class="postage-process.list.item.text.content">Add the details of the item you are sending - add up to 10 items in total</p></span>
            </li>
            <li class="postage-process.list.item">
                <span class="postage-process.list.item.icon --pay"></span>
                <span class="postage-process.list.item.text"><p class="postage-process.list.item.text.content">Pay for your item(s) using a credit or debit card.</p></span>
            </li>
            <li class="postage-process.list.item">
                <span class="postage-process.list.item.icon --post"></span>
                <span class="postage-process.list.item.text"><p class="postage-process.list.item.text.content">Follow the instructions provided to get your items to us</p></span>
            </li>
        </ul>
        <!--
            <div class="postage-process.info">
                <ol class="postage-process.info.list">
                    <li class="postage-process.info.list.item">If you are not using a tracked or signature service and don't require proof of postage</li>
                    <li class="postage-process.info.list.item">Collections available (fees may apply)</li>
                </ol>
            </div>
        -->
    </section>
</template>

<script>
    export default {
        name: 'PostageProcess'
    }
</script>