<template>
    <div class="container">
      <fieldset class="recipient-details">
        <h2 class="recipient-details.title">Recipient details</h2>
        <label for="recipientFirstName" class="form-label">First name<span class="form-label.required">*</span></label>
        <div class="form-text-field.container" :class="{ '--valid': firstNameValid === true}">
          <input 
            type="text" 
            class="form-text-field" 
            name="recipientFirstName" 
            v-model.trim="mainstore.selected.recipientDetails.firstName" 
            @blur="validateFirstName"
          />
        </div>
        <p class="error" v-if="firstNameTouched && firstNameValid === false">You must enter a first name greater than three characters in length</p>
        <label for="recipientLastName" class="form-label">Last name<span class="form-label.required">*</span></label>
        <div class="form-text-field.container" :class="{ '--valid': lastNameValid === true}">
          <input 
            type="text" 
            class="form-text-field" 
            name="recipientLastName" 
            v-model.trim="mainstore.selected.recipientDetails.lastName" 
            @blur="validateLastName"
          />
        </div>
        <p class="error" v-if="lastNameTouched && lastNameValid === false">You must enter a last name greater than two characters in length</p>
        <p class="error" v-if="firstNameTouched && lastNameTouched && fullNameValid === false">Both the first and last name must be less than 40 characters combined</p>
        <div v-if="mainstore.selected.serviceDetails.controlCode !== 'IM'">
            <label for="recipientContactTelephone" class="form-label">Recipient contact telephone<span class="form-label.required">*</span></label>
            <div class="form-text-field.container" :class="{ '--valid': telephoneValid === true}">
            <input 
                type="tel" 
                class="form-text-field"
                name="recipientContactTelephone" 
                v-model.trim="mainstore.selected.recipientDetails.telephone" 
                @blur="validateTelephone"
            />
            </div>
            <p class="error" v-if="telephoneTouched && telephoneValid === false">You must enter a valid telephone number</p>
        </div>
      </fieldset>
      <fieldset class="recipient-details">
        <h2 class="recipient-details.title">Destination address</h2>
        <AddressForm 
          @valid="validateAddress" 
          @invalid="invalidateAddress"
          @changed="validate" 
          v-if="addressPayload.length !== 0"
          :payload="addressPayload"
        />
        <LoaderIcon v-else></LoaderIcon>
        <label for="recipientCountry" class="form-label">Country</label>
        <div class="form-field-icon --disabled">
          <input disabled type="text" class="form-text-field --disabled" name="recipientCountry" v-model="mainstore.selected.destination.name"/>
        </div>
      </fieldset>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  import { useMainStore } from '@/stores/MainStore.ts';
  import AddressForm from '@/components/form-components/AddressForm.vue';
  import LoaderIcon from '@/components/form-components/LoaderIcon.vue';
  
  export default {
    name: 'RecipientDetails',
    components: {
      AddressForm,
      LoaderIcon
    },
    setup() {
      const mainstore = useMainStore();
      return {
        mainstore
      };
    },
    data() {
      return {
        addressPayload: [],
        firstNameValid: null,
        firstNameTouched: false,
        lastNameValid: null,
        lastNameTouched: false,
        fullNameValid: false,
        telephoneValid: null,
        telephoneTouched: false,
        addressValid: null,
      };
    },
    created() {
      this.fetchAddressPayload();
      if(this.mainstore.selected.serviceDetails.controlCode === 'IM') {
        this.telephoneValid = true;
      }
    },
    mounted() {
      if(this.mainstore.selected.recipientDetails.address.RECIPIENTADDRESSLINE1) {
        this.validateFirstName();
        this.validateLastName();

        if(this.mainstore.selected.serviceDetails.controlCode !== 'IM') {
          this.validateTelephone();
        }
      }
    },

    methods: {
      fetchAddressPayload() {
        axios({
          method: 'get',
          url: process.env.VUE_APP_API_CUSTOMSFORMAT,
          params: {
            product: this.mainstore.selected.serviceDetails.id,
            country: this.mainstore.selected.destination.alpha3Code,
            itemformat: this.mainstore.selected.parcelSize.itemFormat
          },
          headers: {
            'Authorization': process.env.VUE_APP_AUTHORIZATION,
          }
        })
        .then(response => {
          this.addressPayload = response.data.recipientLines;
        })
        .catch(err => {
          console.error('Error fetching address payload:', err);
        });
      },
      validateFirstName() {
        let data = this.mainstore.selected.recipientDetails.firstName;
        this.firstNameValid = data !== '' && data.length >= 3;
        this.firstNameTouched = true;
        this.fullNameValid = data.length + this.mainstore.selected.recipientDetails.lastName.length < 40;
        this.validate();
      },
      validateLastName() {
        let data = this.mainstore.selected.recipientDetails.lastName;
        this.lastNameValid = data !== '' && data.length >= 2;
        this.lastNameTouched = true;
        this.fullNameValid = data.length + this.mainstore.selected.recipientDetails.firstName.length < 40;
        this.validate();
      },
      validateTelephone() {
        let data = this.mainstore.selected.recipientDetails.telephone;
        // eslint-disable-next-line
        this.telephoneValid = /^[\(\)\+ 0-9]{10,20}$/.test(data);
        this.telephoneTouched = true;
        this.validate();
      },
      validateAddress() {
        this.addressValid = true;
        this.validate();
      },
      invalidateAddress() {
        this.addressValid = false;
        this.validate();
      },
      validate() {
        this.mainstore.validations.recipientDetailsValid =
          this.firstNameValid &&
          this.lastNameValid &&
          this.fullNameValid &&
          this.telephoneValid &&
          this.addressValid;
      }
    }
  };
  </script>
  
  