<template>
    <nav class="navigation" :class="{ 'sticky': isSticky }"> 
        <div class="navigation.content-container">
            <div class="navigation.home-link">
                
                    <div class="navigation.logo-container">
                        <router-link to="/"><div class="navigation.logo"></div></router-link>
                    </div>
                    <div class="navigation.logo-container --dark-bg">
                        <router-link to="/"><div class="navigation.secondary-logo"></div></router-link>
                    </div>

            </div>
            <ul class="navigation.list">
                <ul class="navigation.list.list-group">
                    <li id="basket-button" class="navigation.list.list-item --icon --basket" @click="toggleBasket">Basket<span class="navigation.list.list-item.count" v-show="basketStore.basketCount > 0">{{ basketStore.basketCount }}</span></li>
                    <li class="navigation.list.list-item --icon --menu" @click="toggleMenu" v-if="!showMenu">Menu</li>
                    <li class="navigation.list.list-item --icon --menu --open" @click="toggleMenu" v-else>Menu</li>
                </ul>
            </ul>
        </div>
        <BasketDropdown v-show="showBasket" @outsideBasketClick="handleBasketOutsideClick"/>
    </nav>
    <Transition><MainMenu v-show="showMenu" @close-menu="closeMenu"/></Transition>
</template>

<script>
    import { useBasketStore } from '@/stores/BasketStore.ts';
    import { useMainStore } from '@/stores/MainStore.ts';
    import { useCollectionStore } from '@/stores/CollectionStore.ts';
    import MainMenu from '@/components/MainMenu.vue';
    import BasketDropdown from '@/components/BasketDropdown.vue';

    export default {

        name: 'SiteNavigation',

        components: {
            MainMenu,
            BasketDropdown
        },

        setup() {
            const basketStore = useBasketStore();
            const mainStore = useMainStore();
            const collectionStore = useCollectionStore();

            return {
                basketStore,
                mainStore,
                collectionStore
            }
        },

        data() {
            return {
                showMenu: false,
                showBasket: false,
                isSticky: false,
            }
        },

        watch: {
            $route() {
                this.showMenu = false;
            }
        },

        mounted() {
            window.addEventListener('scroll', this.handleScroll);
            
        },

        beforeUnmount() {
            window.removeEventListener('scroll', this.handleScroll);
        },

        methods: {
            toggleMenu() {
                this.showMenu = !this.showMenu;
            },

            closeMenu() {
                this.showMenu = false;
            },

            toggleBasket() {
                this.showBasket = !this.showBasket;
            },

            handleBasketOutsideClick() {
                this.showBasket = false;
            },

            handleScroll() {
                const scrollThreshold = 100;

                if (window.scrollY > scrollThreshold) {
                    this.isSticky = true;
                } else {
                    this.isSticky = false;
                }

                if (this.isSticky === true) {
                    this.$emit('sticky-active');
                }
            },

            clearData() {
                window.resetLocalStorage();
                this.mainStore.reset();

                if(this.basketStore.basketCount < 1) {
                    this.collectionStore.resetCollection();
                }
            }
        }

    }

</script>

<style>
    .v-enter-active,
    .v-leave-active {
        transition: opacity 0.2s ease;
    }

    .v-enter-from,
    .v-leave-to {
        opacity: 0;
    }

    .sticky {
        position: fixed;
        top: 0;
        width: 100%;
        z-index: 1000;
    }
</style>