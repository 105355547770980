<template>
    <div class="basket-dropdown" ref="basketDropdown">
        <span class="basket-dropdown.heading">There are <span class="basket-dropdown.heading.count">{{ basketstore.basketCount }}</span> items in your basket</span>
        <div v-if="basketstore.basketCount === 0">
            <span class="basket-dropdown.no-results">You currently have no items in your basket</span>
        </div>
        <div class="basket-dropdown.items" v-for="item in basketstore.basketItems" :key="item.id">
            <BasketItem>
                <template v-slot:name>{{ item.parcelSize.name }}</template>
                <template v-slot:recipient>{{ item.recipientDetails.firstName }}</template>
                <template v-slot:destination>{{ item.destination.name }}</template>
                <template v-slot:service>{{ item.serviceDetails.serviceName }}</template>
                <template v-slot:insurance v-if="item.serviceDetails.selectedInsurance">Insured to £{{ item.serviceDetails.selectedInsurance.value/100 }}</template>
                <template v-slot:price v-if="item.serviceDetails.selectedInsurance && item.serviceDetails.selectedInsurance.price !== undefined && item.serviceDetails.selectedInsurance.value !== null">£{{ ((item.serviceDetails.priceInPence || 0) + (item.serviceDetails.selectedInsurance.price || 0)).toFixed(2) / 100 }}</template>
                <template v-slot:price v-else>£{{ (item.serviceDetails.priceInPence/100).toFixed(2) }}</template>
            </BasketItem>
        </div>
        <div class="basket-dropdown.collection-item" v-if="collectionstore.collectionData.methodOfSupply === 'COLLECTION'">
            <span class="basket-dropdown.collection-item.title">
                Collection
            </span>
            <span class="basket-dropdown.collection-item.price" v-if="mainstore.collectionPrice !== null && mainstore.collectionPrice !== 0">
                £{{ mainstore.collectionPrice.toFixed(2) / 100 }}
            </span>
            <span class="basket-dropdown.collection-item.price" v-else>
                FREE
            </span>
        </div>
        <div class="basket-dropdown.total" v-if="basketstore.basketCount > 0">
            <span class="basket-dropdown.total.text">Total price:</span>
            <span class="basket-dropdown.total.price">£{{ basketTotal }}<span v-if="basketstore.hasTaxCode === true" class="basket-dropdown.total.price.text"> incl. VAT</span></span>
        </div>
        <router-link to="/basket" class="main-button --dark" v-if="basketstore.basketCount > 0 && this.$route.name !== 'basket'"><span class="main-button.text">Go to basket</span></router-link>
    </div>
</template>

<script>
    import BasketItem from '@/components/BasketItem.vue';
    //import MainButton from '@/components/MainButton.vue';
    import { useBasketStore } from '@/stores/BasketStore.ts';
    import { useCollectionStore } from '@/stores/CollectionStore.ts';
    import { useMainStore } from '@/stores/MainStore.ts';
    import { ref } from 'vue';

    export default ({
        setup() {
            const basketstore = useBasketStore();
            const collectionstore = useCollectionStore();
            const mainstore = useMainStore();
            const { basketCount, total } = ref(basketstore);

            return {
                basketstore,
                basketCount,
                total,
                collectionstore,
                mainstore
            }
        },

        name: 'BasketDropdown',

        components: {
            BasketItem,
            //MainButton
        },

        computed: {
            basketTotal() {
                if (this.basketstore.consignmentData.orderType === 'COLLECTION') {
                    return ((this.mainstore.collectionPrice + this.basketstore.total) / 100).toFixed(2);
                } else {
                    return (this.basketstore.total / 100).toFixed(2);
                }
            }
        },

        mounted() {
            document.body.addEventListener('click', this.handleClickOutsideBasket);
        },

        beforeUnmount() {
            document.body.removeEventListener('click', this.handleClickOutsideBasket);
        },

        methods: {
            handleClickOutsideBasket(event) {
                if (
                    // eslint-disable-next-line
                    this.$refs.basketDropdown.contains(event.target) || event.target.id === 'basket-button'
                ) {
                    return;
                }
                
                this.$emit('outsideBasketClick');
            },
        },

    })
</script>

