<template>
    <section class="safe-fly-card">
        <div class="safe-fly-card.inner">
            <div class="safe-fly-card.header">
                <h2 class="safe-fly-card.header.title">Are you sending a Prohibited Item?</h2>
            </div>
            <div class="safe-fly-card.content">
                <p class="safe-fly-card.content.text">Some items carry restrictions when sending off-island. Please ensure that you are not sending any item that is <a href="https://www.iompost.com/for-you/sending-mail/preparing-to-send-your-mail/prohibited-and-restricted-items/" target="_blank">prohibited or restricted</a>.</p>
                <p>To send restricted items (including Lithium-ion batteries and perfumes) you must visit an Isle of Man Post Office Counter where you will be provided the required labels. Prohibited and restricted items cannot be sent using Online Postage.</p>
                <!--
                    <p class="safe-fly-card.content.text">Please ensure your item is Safe to Post and adheres to the rules laid out in the <a href="iompom-safe-to-fly-a4-booklet-lo-2021-22-181121.pdf" target="_blank" class="safe-fly-card.content.text.link">Prohibitions and Restrictions in UK and International Post Guide</a></p>
                -->
            </div>
            <!--
                <DownloadButton btntxt="Download PDF guide" url="iompom-safe-to-fly-a4-booklet-lo-2021-22-181121.pdf"/>
            -->
            <!--<DownloadButton btntxt="Download PDF summary"/>-->
        </div>
    </section>
</template>

<script>
//import DownloadButton from './DownloadButton.vue';

export default {
    name: 'SafeflyCard',

    components: {
        //DownloadButton
    }
}

</script>