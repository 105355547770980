<template>
    <!--<PageTitle title="Safe to Fly" background="--safe-to-fly"/>-->
    <fieldset class="safe-to-fly container" style="margin-top: -55px;">
        <SafeflyCard/>

        <!--
            Simon requested the below feature be removed as it isn't important to users.
            I have added it back as it is important for users to understand what they cannot send
            <ProhibitedItemList/>
        -->
        <!--
            <SafeToFlyLabels/>
        -->
        <SafeToFlyConfirmation/>
    </fieldset>
</template>

<script>
    import SafeflyCard from '@/components/SafeflyCard.vue';
    //import ProhibitedItemList from '@/components/ProhibitedItemList.vue';
    //import SafeToFlyLabels from '@/components/SafeToFlyLabels.vue';
    import SafeToFlyConfirmation from '@/components/SafeToFlyConfirmation.vue';
    //import PageTitle from '@/components/PageTitle';
    //import { useFormStore } from '@/stores/FormStore.ts';
    import { useMainStore } from '@/stores/MainStore.ts';
    import { ref } from 'vue';

    export default {
        setup() {
            const mainstore = useMainStore()
            const { basketItems } = ref(mainstore)
            return {
                mainstore,
                basketItems
            }
        },

        name: 'SafeToFly',

        components: {
            SafeflyCard,
            //ProhibitedItemList,
            //SafeToFlyLabels,
            SafeToFlyConfirmation,
            //PageTitle
        },

        methods: {
            addItem() {
                this.store.push('addToBasket', [{
                    value: 100,
                }
                ])
            }
        }
    }
        
</script>