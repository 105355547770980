<template>
    <div class="basket-dropdown.items.item">
        <span class="basket-dropdown.items.item.text">
            <span class="basket-dropdown.items.item.text.name">
                <slot name="name"></slot>
            </span>
            <span>
                <slot name="service"></slot>
            </span>
            <span>Going to 
                <slot name="recipient"></slot> 
                in 
                <slot name="destination"></slot>
            </span>
            <span>
                <slot name="insurance"></slot>
            </span>
        </span>
        <span class="basket-dropdown.items.item.price"><slot name="price"></slot></span>
    </div>
</template>

<script>
    export default {
        name: 'BasketItem'
    }
</script>