<template>
  <div class="typeahead-address.address-lookup">
    <label for="typeahead" class="typeahead-address.address-lookup.label"
      >Look up address (please include space in postcodes)</label
    >
    <!--Below item is an npm package for vue3 called vue3-simple-typeahead. Docs can be found here: https://www.npmjs.com/package/vue3-simple-typeahead The styles specific to the input field can be found in _typeahead.scss-->
    <vue3-simple-typeahead
        id="typeahead"
        class="typeahead"
        placeholder="Enter the postcode or address"
        :items="addressValues"
        :minInputLength="1"
        @keyup="getAddresses()"
        v-model="selectedAddressFormatted"
        :itemProjection="itemProjectionFunction"
        @selectItem="selectItemEventHandler"
    >
    </vue3-simple-typeahead> 
    <p class="error" v-if="valid == false">You must select an address</p>
    <!--<span class="typeahead-address.address-lookup.manual" v-if="this.manualEntry == false" @click="this.manualEntry = true">Enter address manually</span>-->
  </div>
</template>

<script>

import axios from "axios";
import { useMainStore } from '@/stores/MainStore.ts';
import { useBasketStore } from '@/stores/BasketStore.ts';
import { useCollectionStore } from '@/stores/CollectionStore.ts';

export default {

  setup() {
    const mainstore = useMainStore()
    const basketstore = useBasketStore()
    const collectionstore = useCollectionStore()
    return {
        mainstore,
        basketstore,
        collectionstore
    }
  },

  name: "TypeaheadAddress",
  data() {
    return {
        selectedAddressFormatted: "",
        addressValues: []
    };
  },

  props: {
    valid: Boolean
  },

  emits: [
   'changed'
  ],

  computed: {
    selectedAddress() {
      // Find the corresponding object from addressValues array
      const selectedAddressObj = this.addressValues.find((address) =>
        address.address === this.selectedAddressFormatted
      );
      if (selectedAddressObj && selectedAddressObj.address) {
        // Return only the required fields as an object
        return {
          ORG: selectedAddressObj.address.ORG,
          ADDRESSLINE1: selectedAddressObj.address.ADDRESSLINE1,
          ADDRESSLINE2: selectedAddressObj.address.ADDRESSLINE2,
          ADDRESSLINE3: selectedAddressObj.address.ADDRESSLINE3,
          CITY: selectedAddressObj.address.CITY,
          POSTCODE: selectedAddressObj.address.POSTCODE
        };
      } else {
        return {};
      }
    }
  },
  methods: {
    getAddresses() {
      axios({
        method: "get",
        url: process.env.VUE_APP_API_PAFSEARCH,
        headers: {
            'Authorization': process.env.VUE_APP_AUTHORIZATION,
        },
        params: {
          q: this.selectedAddressFormatted,
          format: "INTERNATIONAL",
        },
      })
        .then((response) => {
          this.addressValues = response.data.addresses;
        })
        .catch((err) => console.error(err));
    },
    // Define the itemProjection function here to only display the formatted address in the dropdown
    itemProjectionFunction(item) {
      if (item.address == null) {
        return "";
      }

      const addressParts = [];

      if(item.address.ORG) {
        addressParts.push(item.address.ORG);
      }

      if (item.address.ADDRESSLINE1) {
        addressParts.push(item.address.ADDRESSLINE1);
      }

      if (item.address.ADDRESSLINE2) {
        addressParts.push(item.address.ADDRESSLINE2);
      }

      if (item.address.ADDRESSLINE3) {
        addressParts.push(item.address.ADDRESSLINE3);
      }

      if (item.address.CITY) {
        addressParts.push(item.address.CITY);
      }

      if (item.address.POSTCODE) {
        addressParts.push(item.address.POSTCODE);
      }

      return addressParts.join(", ");
    },

    selectItemEventHandler(selectedValue) {
      this.selectedAddressFormatted = selectedValue;
      this.mainstore.selected.sendersDetails.address = selectedValue;
      this.collectionstore.collectionData.collectionAddress = selectedValue;
      this.basketstore.sendersAddress = selectedValue;
      this.$emit('changed');
    },

  }
};
</script>
