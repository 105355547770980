<template>
    <div class="interstitial-loader">
        <div class="interstitial-loader.icon">
            <LoaderIcon />
        </div>
    </div>
</template>

<script>
import LoaderIcon from '@/components/form-components/LoaderIcon.vue';

export default {
    name: 'InterstitialLoader',

    components: {
        LoaderIcon
    },
}
</script>