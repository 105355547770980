import { defineStore } from 'pinia';
import { useStorage } from '@vueuse/core';

export const useCollectionStore = defineStore('CollectionStore', {

  state: () => ({
    lastUpdated: useStorage('collectionLastUpdated', null),

    collectionData: useStorage('CollectionData', {
      hasPrinter: null,
      proceedNoCollection: null,
      collectionRequired: false,
      collectionAddress: [],
      collectionDate: null,
      safePlace: '',
      collectionItems: [],
      methodOfSupply: ''
    }),

    agreementRequired: false,
    customerAgrees: false

  }),

  actions: {
    resetCollection() {
      this.lastUpdated = new Date(); // Time of the last update

      this.collectionData = {
        hasPrinter: null,
        proceedNoCollection: null,
        collectionRequired: false,
        collectionAddress: [],
        collectionDate: null,
        safePlace: '',
        collectionItems: [],
        methodOfSupply: ''
      },
      this.agreementRequired = false,
      this.customerAgrees = false
    },

    isOlderThanMidnight() {
      if (!this.lastUpdated) return false;

      const now = new Date();
      const midnight = new Date(now.getFullYear(), now.getMonth(), now.getDate());
      return new Date(this.lastUpdated) < midnight;
    }
  }
})