<template>
    <PageTitle title="Terms and Conditions"/>
    <section class="container">
        <div class="content rich-text-editor">
            <p>This Service allows You to manage shipping, purchase Postage Services, input customs information and generate relevant customs documentation, and print Postage Labels</p>
            <p>We recommend that You retain a copy of these Terms for Your records.</p>
            <p>This Agreement is between You and the IOMPO and governs Your use of IOMPO Online Postage (as defined in clause 1 (Definitions) below). Please note that we do not store a copy of this Agreement against Your name.</p>
            <p>This Agreement should be read in conjunction with:</p>
            <ul>
                <li>our Privacy Notice <router-link to="/privacyPolicy">here</router-link></li>
                <li>our Cookie Policy <router-link to="/cookiePolicy">here</router-link></li>
                <li>our Security Policy at <a href="https://www.iompost.com/help/security-policy/">https://www.iompost.com/help/security-policy/</a></li>
                <li>our General Terms & Conditions at <a href="https://www.iompost.com/uploads/iompom-general-terms-conditions_020714-v4-may2024.pdf">https://www.iompost.com/uploads/iompom-general-terms-conditions_020714-v4-may2024.pdf</a></li>
            </ul>
            <ol>
                <li>
                    Available to
                    <ol>
                        <li>Customers registered in the Isle of Man. </li>
                    </ol>
                </li>
                <li>
                    Definitions
                    <ol>
                        <li>'Access' means access to the Site as appropriate for the Shipping Solution(s) that You are using.</li>
                        <li>'Agreement' means this agreement between us and You governing Your usage of IOMPO Online Postage.</li>
                        <li>'Cancellation Period' means the period of fourteen (14) calendar days beginning with the day after the day on which the purchase of the relevant Postage Service was made.</li>
                        <li>'Customer' means any legal person applying for the Service.</li>
                        <li>'Customer Data' means any documentation, data or information that You upload to the Site, (including Your customer's data their first name, last name, address (including postcode) and where applicable telephone number, email address and any details about the item that You are dispatching.</li>
                        <li>'Data Controller' means data controller as defined in Data Protection Legislation.</li>
                        <li>'Data Protection Legislation' means the Data Protection (Application of GDPR) Order 2018 and the Data Protection (Application of LED) Order 2018 and any regulations made thereunder or any other instruments relating to the protection of personal data as implemented in the Isle of Man pursuant to the Data Protection Act 2018 and any relevant national laws implementing Directives 95/46EC, 2002/58/EC 97/66/EC and 2016/679 (“GDPR”), all as amended or replaced from time to time.</li>
                        <li>'Data Subjects' means data subjects as in the Data Protection Legislation.</li>
                        <li>'Documentation' means the documentation, procedures and related materials made available by Us in connection with the Site and the Services (including any documentation facilitating the use of the Software).</li>
                        <li>'Group' means us and the following for the time being: our Subsidiaries, and our Holding Companies and their Subsidiaries (and "Subsidiary" and "Holding Company" shall have the meanings given in section 220 of the Companies Act 2006).</li>
                        <li>'Intellectual Property Rights' means intellectual property rights, including (without limitation) patents, trademarks, design rights, copyrights, database rights, trade secrets, trade names, goodwill, know-how, rights in computer software and all rights of an equivalent nature anywhere in the world, together with any applications or rights to apply for the foregoing.</li>
                        <li>'IOMPO' means the Isle of Man Post Office (a Statutory Board of Tynwald).</li>
                        <li>'IOMPO Online Postage' means all aspects of the IOMPO Online Postage service and system other than Your hardware, including the IOMPO Online Postage server and the trade mark "IOMPO Online Postage ". IOMPO Online Postage service comprises the ability to generate a daily manifest of postage to be dispatched, print postage labels where the service requires it, and provide the data required for IOMPO to generate an invoice accordingly.</li>
                        <li>'IOMPO Online Postage Website' means the website located at <a href="https://onlinepostage.iompost.com" target="_blank">onlinepostage.iompost.com</a></li>
                        <li>'IOMPO Materials' means information, data and all Documentation made available on, through the provision of the Services.</li>
                        <li>'Item' means a mail piece to which a Postage Service is purchased.</li>
                        <li>'Mailing' means one (1) batch of postal items logged as a single or consolidated entry.</li>
                        <li>'Our System' means Our computing systems and environment, consisting of hardware, software (including the Software) and communication networks that We use to provide You with the Services.</li>
                        <li>'Personal Data' means personal data as defined in the Data Protection Legislation.</li>
                        <li>'Postage Label' means a postage label created by IOMPO Online Postage displaying a Postage Mark and the address of the recipient of the Item and that may indicate the address of the sender of the Item.</li>
                        <li>'Postage Mark' means a postage mark included in a Postage Label that indicates, among other things, the amount of postage paid on an Item and which enables the sender of the Item to access certain postal services.</li>
                        <li>'Postage Service(s)' is a Service offered by us to You where we will accept an Item to our postal network for onward delivery to its destination within the timeframe offered by that Service.</li>
                        <li>'Postal Services Terms' means any relevant Scheme, statutes, regulations, orders and contractual provisions contained in any separate contract(s) governing the supply of the postal services that You can purchase through IOMPO Online Postage.</li>
                        <li>'Processing' means processing as defined in the Data Protection Legislation, and process and processing shall be interpreted accordingly.</li>
                        <li>'Privacy Notice' means the IOMPO privacy notice found <router-link to="/privacyPolicy">here</router-link></li>
                        <li>'Scheme' means any scheme made under the Post Office Act 1993 as amended from time to time.</li>
                        <li>'Service(s)' means the Online Postage service.</li>
                        <li>'Site' means the IOMPO Online Postage Website.</li>
                        <li>'Software' means all computer software (including any underlying source code and any associated designs and Documentation files) made available to You from time to time on, through or via the Site or the Services.</li>
                        <li>'Terms' means these terms and conditions.</li>
                        <li>'Terms and Conditions' means the general terms and conditions which apply to the delivery of the Service and which can be found at <a href="https://www.iompost.com" target="_blank">www.iompost.com</a></li>
                        <li>'Transaction' means each order of a Postage Label for an item using IOMPO Online Postage</li>
                        <li>'Use' means use of the Site and/or the Service by the Users to carry out a Transaction in accordance with these Terms.</li>
                        <li>'User' means an individual authorised by You to Access IOMPO Online Postage on Your behalf subject to these Terms; </li>
                        <li>'We', 'Us' and Our' means the IOMPO.</li>
                        <li>'Working Day' means any day which is not a Saturday, a Sunday or a bank or public holiday in the United Kingdom or the Isle of Man.</li>
                        <li>'You' and 'Your' means the Customer.</li>
                        <li>'Your System' means Your computing systems and environment, consisting of hardware, software and communication networks that You use to process and store the Customer Data and/or to create Postage Labels.</li>
                        <li>any phrase in this Agreement introduced by the term "include", "including", "in particular" or any similar expression will be construed as illustrative and will not limit the sense of any words preceding that term.</li>
                        <li>all headings are for reference only and shall not be used in interpreting or construing such clause.</li>
                        <li>
                            any reference to:
                            <ol>
                                <li>paragraphs, clauses and Schedules are to the clauses, schedules and paragraphs to these Terms(unless otherwise stated);</li>
                                <li>words in the singular include the plural and vice versa;</li>
                                <li>a 'person' includes a natural person, corporate or unincorporated body (in each case whether or not having separate legal personality) and that person's personal representatives, successors and permitted assigns;</li>
                                <li>a gender includes the gender by which You identify;</li>
                                <li>a 'company' includes any company, corporation or other body corporate, wherever and however incorporated or established;</li>
                                <li>'writing' includes communication by email;</li>
                                <li>legislation is a reference to that legislation as from time to time amended, extended, re-enacted or consolidated and all statutory instruments, orders, by-laws, directions and notices made pursuant to it, whether made before or after the date of this Agreement; and</li>
                                <li>any Manx legal term for any action, remedy, method or judicial proceeding, legal document, legal status, court, official, legal doctrine or any other legal concept or thing will, in respect of any jurisdiction other than the Isle of Man, be deemed to include that which most nearly approximates in that jurisdiction to the Isle of Man legal term.</li>
                            </ol>
                        </li>
                    </ol>
                </li>
                <li>
                    General Terms
                    <ol>
                        <li>The postal services that may be requested through IOMPO Online Postage will be provided pursuant to the Postal Services Terms.</li>
                        <li>The Terms and Conditions apply to the provision of these Services by the IOMPO.</li>
                        <li>Some of the postal services may also have terms additional to the provisions referred to above which will be presented to You when You request the postal services concerned.</li>
                        <li>The postal services chosen by You through IOMPO Online Postage for the conveyance of an Item will be provided pursuant to the Postal Services Terms.</li>
                        <li>Please note that the services covered by this Agreement may be unavailable from time to time, therefore we recommend that You access the IOMPO Online Postage Website regularly in order to check the availability of the services covered by this Agreement and also to ensure that You are aware of any updates and amendments made to such services.</li>
                        <li>We reserve the right to amend this Agreement at any time and will notify You of such amendments.</li>
                        <span>Please note</span>
                        <li>You can only purchase Postage Services that are supported by IOMPO Online Postage.</li>
                        <li>You cannot purchase Postage Services unless the computer from which You are using IOMPO Online Postage is connected to the internet. Your computer must as a minimum requirement use a modern browser with an up-to-date browser version.</li>
                        <li>By using IOMPO Online Postage, You agree to the collection and use of Your data by us in accordance with the terms laid out in our <router-link to="/privacyPolicy">Privacy Notice</router-link></li>
                        <li>If You have any questions, comments or complaints about the IOMPO Online Postage service, You can call our customer service line on 664664</li>
                    </ol>
                </li>
                <li>
                    Entry into Transactions
                    <ol>
                        <li>These Terms will apply to each and every Transaction entered into by You.</li>
                        <li>You shall ensure that You  are aware of these Terms and follow such instructions and prompts as are shown on the Site or provided to You from time to time in order to carry out a Transaction.</li>
                        <li>We will not be under any obligation to enter into or accept any proposed Transaction.</li>
                    </ol>
                </li>
                <li>
                    Intellectual Property Rights and Confidentiality
                    <ol>
                        <li>All right, title, interest and Intellectual Property Rights in the Site, the Services and IOMPO Materials belong to and shall remain vested with Us. Access to or use of the Site or the Services does not grant You or the User any ownership right in the Site, Services or IOMPO Materials.</li>
                        <li>To the extent that You may acquire any Intellectual Property Rights in the Site, the Services, IOMPO Materials, any documentation provided by Us to facilitate use of the Site and any underlying Software, You shall immediately assign or procure the assignment of such Intellectual Property Rights to Us with full title guarantee (including by way of present assignment of future Intellectual Property Rights) and shall execute all such documents as We may consider necessary to give effect to this clause.</li>
                        <li>We will have the conduct of all claims, disputes and proceedings arising from any third party claims in relation to Our Intellectual Property Rights (including any proceedings to which You are a party). We will, in our sole discretion, decide what action (including litigation, arbitration, mediation or compromise), if any, to take in respect of such claims, disputes and proceedings. We will not be obliged to bring or defend any proceedings in relation to any such Intellectual Property Rights.</li>
                        <li>
                            You shall not, and will not assist or facilitate any third party to:
                            <ol>
                                <li>attempt to copy, modify, duplicate, create derivative works from, mirror, republish, download, display, transmit, distribute;</li>
                                <li>attempt to de-compile, reverse compile, disassemble, reverse engineer or otherwise reduce to human-perceivable form; or</li>
                                <li>build, or attempt to build a product or service from the Software and/or any IOMPO Material.</li>
                            </ol>
                            In addition, You shall ensure that You, Your employees, agents or any User does not delete or amend any copyright or other notices displayed on the IOMPO Materials or any copies of them.
                        </li>
                        <li>Subject to clause 6.6 below, if You want to use any of our owned or licensed branding, marks or other intellectual property rights in relation to the Services, then except as expressly allowed under this clause, You may only do so with our prior written consent and only on the condition that Your use is in compliance with any of our branding guidelines.</li>
                        <li>We reserve the right, at any time, to require You to use or display any of our owned licensed branding, marks or other Intellectual Property Rights on or in relation to the Postage Service and/or on a Postage Label. If We require You to do this, We also reserve the right to direct You as to the form, content, look or feel of the branding, marks or other Intellectual Property Rights that We are requiring You to use or display.</li>
                        <li>You shall treat any information exchanged or shared between us under these Terms as confidential and You will not share any of its content with another person without Our written consent or where expressly stated in these Terms that such information can be shared.</li>
                    </ol>
                </li>
                <li>
                    Forming a binding agreement with us 
                    <ol>
                        <li>Set out in the following terms below is a summary of the steps You will need to follow in order to purchase postal services using IOMPO Online Postage.</li>
                    </ol>
                </li>
                <li>
                    Requirements
                    <ol>
                        <li>Any person(s) may use this Service in accordance with the General Terms and Conditions.</li>
                    </ol>
                </li>
                <li>
                    Payment
                    <ol>
                        <li>The purchase price of products will be our stated prices in force at the time when the order is accepted as set out on the IOMPO Online Postage Website and as amended by us from time to time. The only exception to this is where there is an error on our website. If we discover an error in the price of products You have ordered we will inform You as soon as possible and give You the option of confirming Your order at the correct price or cancelling it. If we are unable to contact You we will treat the order as cancelled. If You cancel and You have already paid, You will receive a full refund.</li>
                        <li>Please note that for Postal Services that are subject to VAT, all prices quoted on the IOMPO Online Postage Website are inclusive of VAT.</li>
                    </ol>
                </li>
                <li>
                    Purchase of a Postage Service
                    <ol>
                        <li>Step 1: If You are using IOMPO Online Postage to purchase a Postage Service for the first time, a non-registered user, or if this Agreement has been amended by us since Your last purchase of a Postage Service using IOMPO Online Postage, You agree unconditionally to the terms and conditions set out in this Agreement.</li>
                        <li>Step 2: Where there are any additional terms and conditions that apply to the Postage Service, such additional terms and conditions will appear on Your screen as a link (if applicable).</li>
                        <li>Step 3: On receipt of Your payment confirmation We will begin processing Your order.</li>
                        <li>The purchase of the ordered Postage Service is deemed to be complete and a legally binding agreement will have been formed between us and You when We notify You on screen via the IOMPO Online Postage website that Your payment has been accepted.</li>
                    </ol>
                </li>
                <li>
                    Postage Labels
                    <ol>
                        <li>You are responsible for ensuring that the correct value Postage Label is used. No credit or refund will be made if a Postage Label of greater value than that required is purchased.</li>
                        <li>A Postage Label may not be sold by You for use by anyone else.</li>
                        <li>Any Postage Labels that are not used within 5 days of purchase will be deemed void and shall not be used for delivering Items.</li>  
                    </ol>
                </li>
                <li>
                    Cancellation of a Postage Label
                    <ol>
                        <li>When Your Payment has been confirmed but the postage items have not been handed over to Us, amendments to Your Order can only be conducted in agreement with Us.  You will not have any rights to cancel Your purchase of a Postage Label if You have already used it and posted the corresponding Item, or if We have refused the relevant Item for posting as set out in clause 18 below.</li>
                    </ol>
                </li>
                <li>
                    Warranties and Representations
                    <ol>
                        <li>We have used our reasonable efforts to ensure that the IOMPO Materials are compiled from sources that We believe to be reliable and accurate. We do not guarantee or warrant the accuracy or currency of the IOMPO Materials and the IOMPO Materials may be incomplete or condensed and may be subject to change without notice.</li>
                        <li>All warranties and representation are excluded from these Terms to the extent possible in law, and IOMPO Materials do not warrant that the Site, the use of the Services or the IOMPO Materials, will be uninterrupted or error free.</li>
                        <li>Any Software forming part of the IOMPO Materials is provided "as is".</li>
                    </ol>
                </li>
                <li>
                    Hyperlink
                    <ol>
                        <li>The Site may contain links to other websites, which in turn may contain material that has been produced by third parties not affiliated with us. We have no control over those other websites and accept no responsibility or liability for information or content provided on such websites.</li>
                    </ol>
                </li>
                <li>
                    Your Obligations 
                    <ol>
                        <li>You are responsible for the functionality or performance of Your System to ensure that You can benefit from these Services and the Postage Service. We will not supply, maintain or support Your System. In particular, We are not responsible for the quality of reproduction of a Postage Label, address or logo on an Item to the extent that the quality is affected by your System.</li>
                        <li>You will ensure that Your System is connected to the internet and has one of the following browser applications installed on it: Microsoft Edge, Mozilla Firefox, Safari and/ or Chrome.</li>
                        <li>You guarantee and warrant that all information that You provide to us is true and accurate at the time that such information is provided and can be relied upon to enable Us to provide these Services to You.</li>
                        <li>
                            You must provide us promptly on request with all information that We may reasonably require from time to time in connection with
                            <ol>
                                <li>Your Use of the Site and the Services, and</li>
                                <li>any Transactions.</li>
                            </ol>
                        </li>
                        <li>
                            You must comply with any of our policies and standards that We notify You as being applicable to Your use of the Services. Without prejudice to the generality of the foregoing You must comply with any reasonable security standards and requirements notified by Us to You from time to time, including the following requirements:
                            <ol>
                                <li>in relation to the transport or exchange of messages between You and Us (or Our authorised representatives) You must use SSL/TLS and/or other ciphers approved by Us;</li>
                                <li>You shall ensure that Your System is continuously protected against unauthorised access (remote or otherwise), malware attacks, malicious code, destruction or unauthorised alteration which may affect the Shipping Solution or Our Systems;</li>
                            </ol>
                        </li>
                    </ol>
                </li>
                <li>
                    Viruses, hacking and other offences
                    <ol>
                        <li>
                            You must ensure that You do not:
                            <ol>
                                <li>knowingly introduce any viruses, trojans, worms, logic bombs or other material which is malicious or technologically harmful into the Site or the Services (including, but not limited to the Software); or</li>
                                <li>attack the Site or the Services (including the Software) via a denial-of-service attack or a distributed denial-of-service attack; or</li>
                                <li>Use the Site or the Services (including the Software) for any purpose which is unlawful, abusive, libellous, obscene or threatening.</li>
                            </ol>
                        </li>
                        <li>A breach of clause 17.1 may constitute a criminal offence. We may report any such breach (whether actual or suspected) to the relevant law enforcement authorities and You must co-operate with those authorities by disclosing Your identity to them.</li>
                        <li>We will not be liable for any loss or damage caused by a distributed denial-of-service attack, viruses or other technologically harmful material that may infect Your computer equipment, computer programs, data or other proprietary material due to Use of the Site or Services or accessing or downloading any Royal Mail Materials.</li>
                    </ol>
                </li>
                <li>
                    Discretion to refuse Items for posting
                    <ol>
                        <li>
                            We reserve the right to refuse an Item for posting if: 
                            <ol>
                                <li>the Postage Label on the Item has expired;</li>
                                <li>the Postage Label on the Item is of an insufficient value for the postal service requested for it;</li>
                                <li>You have cancelled the purchase of the Postage Service on the Item;</li>
                                <li>the Postage Label on the Item is a duplicate of a Postage Label that has already been used on another Item;</li>
                                <li>the Postage Label on the Item is obscured, defaced, incomplete or otherwise unreadable or invalid;</li>
                                <li>where You hand an Item into a Post Office® for postage and You do not present the requisite documentation and/or information as indicated to You during Your IOMPO Online Postage purchase journey on the IOMPO Online Postage Website.</li>
                            </ol>
                        </li>
                    </ol>
                </li>
                <li>
                    Termination of Agreement
                    <ol>
                        <li>
                            We may terminate this Agreement immediately by notifying You in writing (including by email) if:
                            <ol>
                                <li>We believe You have mailed or attempted to mail an Item of a type referred to in any of paragraphs 18.1.1 - 18.1.6 inclusive of clause 18 (Discretion to refuse Items for posting); or</li>
                                <li>You commit any other material breach of this Agreement or the Postal Services Terms and (if capable of remedy) fail to remedy such breach within thirty (30) days of being required to do so.</li>
                            </ol>
                        </li>
                        <li>We will have absolute discretion to suspend or terminate this Agreement at any time if we suspect that You have committed a fraudulent act whilst using IOMPO Online Postage or have used Online Postage for any other dishonest or immoral purpose.</li>
                        <li>In addition to Your rights to terminate set out elsewhere in this Agreement, You may terminate this Agreement if we commit a material breach of this Agreement and (if capable of remedy) fail to remedy such breach within thirty (30) days of being required to do so.</li>
                    </ol>
                </li>
                <li>
                    Postal Services Terms
                    <ol>
                        <li>Nothing in this Agreement excludes or limits Your rights or obligations under the Postal Services Terms.</li>
                        <li>Your entitlement or otherwise to compensation for lost, damaged or delayed Items is subject to the provisions of the relevant Postal Services Terms.</li>
                    </ol>
                </li>
                <li>
                    Limitation of liability
                    <ol>
                        <li>The provisions on Limitation of Liability set out in the Terms and Conditions apply to this Service.</li>
                    </ol>
                </li>
                <li>
                    Data
                    <ol>
                        <li>You hereby acknowledge and agree that in order for You to receive the benefit of this Service You will need to provide us with Customer Data and in handling such Customer Data We will act as Data Controller (for the purposes of Data Protection Legislation).</li>
                        <li>Each party shall, at all times, comply with its obligations under the Data Protection Legislation in performing its obligations under this Agreement; and You will ensure that, whenever You provide Us with, or permit Us to access Personal Data for us to Process in performing our obligations under this Agreement, such provision or access for that purpose is lawful and that We may lawfully so Process that Personal Data.</li>
                        <li>
                            Without prejudice to the generality of clauses 23.1 and 23.2 above, before providing us with or permitting us access to any Personal Data under this Agreement, You will ensure that, or You will procure that the Data Subjects concerned:
                            <ol>
                                <li>understand that We will be Processing their Personal Data;</li>
                                <li>have been provided with such information as is required by the Data Protection Legislation regarding the collection, use, Processing, disclosure, and overseas transfer of their Personal Data;</li>
                                <li>have provided their valid consent to our processing of their Personal Data under these Terms or that another legal basis for such Processing is satisfied; and</li>
                                <li>are aware of their rights under the Data Protection Legislation and how to exercise these.</li>
                            </ol>
                        </li>
                        <li>You will, from time to time and on request, provide such evidence as We may reasonably request to demonstrate Your compliance with conditions 23.2 and 23.3.</li>
                        <li>You shall indemnify us for any loss incurred by us in relation to Your breach of conditions 23.2 and 23.3 above.</li>
                        <li>All statistical analysis and use of data that we have collated (including Customer Data) will be carried out by Us in accordance with our Privacy Notice.</li>
                    </ol>
                </li>
                <li>
                    General
                    <ol>
                        <li>Any failure or delay by You or us in enforcing (in whole or in part) any provision of this Agreement will not be interpreted as a waiver of Your or our rights.</li>
                        <li>If any provision or part of a provision, of this Agreement is found by any court or authority of competent jurisdiction to be unlawful, otherwise invalid or unenforceable, such provision or part-provision will be struck out of this Agreement and the remainder of this Agreement will apply as if the offending provision or part-provision had never been agreed.</li>
                        <li>You may not transfer any of Your rights or obligations under this Agreement without our prior written consent and We may not transfer any of our rights or obligations under this Agreement without Your prior written consent, although We may transfer any of our rights or obligations under this Agreement to another company in our Group without Your consent.</li>
                        <li>
                            Subject to clause 21 (Postal Services Terms), this Agreement and the Terms and Conditions is the entire agreement between You and us for Your use of IOMPO Online Postage and supersedes all prior:
                            <ol>
                                <li>agreements;</li>
                                <li>representations; and</li>
                                <li>understandings, in relation to Your use of IOMPO Online Postage, in each case whether these have been made orally or in writing.</li>
                            </ol>
                        </li>
                        <li>Each party acknowledges that it has not been induced to enter into this Agreement by reason of any representation made by or on behalf of the other party, except where any representation has been made by or on behalf of the other party fraudulently.</li>
                        <li>This Agreement and any non-contractual obligations or liabilities arising out of or in connection with the performance of this Agreement is governed by and will be construed in accordance with Isle of Man Law. Each party submits to the exclusive jurisdiction of the Courts of the Isle of Man.</li>
                        <li>The headings in this Agreement are included for convenience only and will not affect its interpretation.</li>
                    </ol>
                </li>
            </ol>
            
        </div>
    </section>
</template>

<script>
    import PageTitle from '@/components/PageTitle.vue';

    export default {
        name: 'TermsConditions',

        components: {
            PageTitle
        }
    }
</script>