<template>
    <div class="form-progress">
        <div class="form-progress.nav-container">
            <div class="form-progress.nav-container.inner">
                <FormstepIcon 
                    v-for="step in filteredSteps" 
                    :key="step.number" 
                    :count="step.number"
                    :validation="step.validation" 
                    :link="step.link" 
                    :title="step.title" />
            </div>
        </div>
    </div>
</template>
  
<script>
import FormstepIcon from '@/components/FormstepIcon.vue';
import { useMainStore } from '@/stores/MainStore.ts';
import { useCollectionStore } from '@/stores/CollectionStore.ts';
import { useBasketStore } from '@/stores/BasketStore.ts';

export default {
    name: 'FormProgress',
    components: {
        FormstepIcon,
    },
    setup() {
        const mainstore = useMainStore();
        const collectionstore = useCollectionStore();
        const basketstore = useBasketStore();
        return { mainstore, collectionstore, basketstore };
    },
    computed: {
        filteredSteps() {
            let steps = [
                {
                    title: 'Posting method',
                    link: '/my-order/provide-items',
                    validation: this.mainstore.validations.provideItemsValid,
                },
                {
                    title: 'Parcel size',
                    link: '/my-order/parcel-size',
                    validation: this.mainstore.validations.parcelSizeValid,
                },
                {
                    title: 'Service',
                    link: '/my-order/services',
                    validation: this.mainstore.validations.serviceValid,
                },
                {
                    title: 'Recipient details',
                    link: '/my-order/recipient-details',
                    validation: this.mainstore.validations.recipientDetailsValid,
                },
                {
                    title: 'Senders details',
                    link: '/my-order/senders-details',
                    validation: this.mainstore.validations.sendersDetailsValid,
                },
                {
                    title: 'Customs',
                    link: '/my-order/customs',
                    validation: this.mainstore.validations.customsValid,
                },
                {
                    title: 'Order summary',
                    link: '/my-order/order-summary',
                    validation: this.mainstore.validations.orderSummaryValid,
                },
                {
                    title: 'Safe to post',
                    link: '/my-order/safe-to-fly',
                    validation: this.mainstore.validations.safeToFlyValid,
                },
            ];

            // conditions

            //if (this.collectionstore.collectionData.methodOfSupply !== '') {
            //    steps = steps.filter((step) => step.title !== 'Method of supply');
            //}

            if (this.basketstore.basketCount > 0) {
                steps = steps.filter((step) => step.title !== 'Posting method');
            }

            if (this.mainstore.selected.destination.alpha3Code === 'IMN' || this.mainstore.selected.destination.alpha3Code === 'GBR') {
                steps = steps.filter((step) => step.title !== 'Customs');
            }

            // update step numbers
            steps.forEach((step, index) => {
                step.number = index + 1;
            });

            return steps;
        },
    },
};
</script>