<template>
    <PageTitle title="Collection" background="--parcel-postboxes"/>
    <section class="container">
        <div class="content rich-text-editor">
            <p>For your convenience, you can request a collection from your home address.</p>
            <h2>Booking your collection</h2>
            <!--
                <p>You can choose to add collection to any service for which collection is an option from the basket page of the Online Postage website.</p>
            -->
            <p>Collections can be booked any day for three days in the future from the day following your purchase.</p>
            <p>We do not currently offer a collection time however, you may specify a safe place from which we can collect your item. Items left in a safe place are your responsibility. We cannot be held responsible should anything happen to your item prior to collection by a member of our team.</p>
            <p>Collections are available from Monday through to Friday. We do not offer weekend collection.</p>
            <h2>Cost of collection</h2>
            <p>The cost for a collection is a flat fee shown at the time of booking and is the same irrespective of the location of the collection. Please be aware that items over 20kg may be subject to a heavy item surcharge, applied to cover any additional staffing required to handle your heavy item.</p>
            <h2>Collection of items</h2>
            <p>If an item is not ready on calling you will need to take your item to an Isle of Man Post Office counter. Missed collections due to customer error are not subject to a refund. To avoid missing your collection we recommend providing a safe place when prompted and leaving your item there.</p>
            <h2>Receipt of items</h2>
            <p>Items will not be confirmed as collected until they are scanned at a post office depot. We do not provide receipt on collection from your location. We do not offer collections on weekends or bank holidays.</p>
        </div>
    </section>
</template>

<script>
    import PageTitle from '@/components/PageTitle.vue';

    export default {
        name: 'CollectionContent',

        components: {
            PageTitle
        }
    }
</script>