<template>
    <a class="skip-to-content-link" href="#content">
        Skip to content
    </a>
</template>

<script>
    export default {
        name: 'SkipLinks',
    }
</script>