<template>
    <fieldset class="customs-checkboxes">
        <label for="nogoods" class="customs-checkboxes.item">
            I am not sending goods
            <input type="radio" name="goods" id="nogoods" value="no goods" v-model="mainstore.selected.sendingGoods" @change="validateFields()"/>
            <span class="customs-checkboxes.item.checkmark"></span>
        </label>
        <label for="goods" class="customs-checkboxes.item">
            I am sending goods
            <input type="radio" name="goods" id="goods" value="goods" v-model="mainstore.selected.sendingGoods" @change="validateFields()"/>
            <span class="customs-checkboxes.item.checkmark"></span>
        </label>
        <label class="customs-checkboxes.item" v-if="mainstore.selected.sendingGoods === 'goods'">
            I am sending on behalf of a VAT registered business
            <input type="checkbox" name="vatRegistered" id="vatRegistered" v-model="mainstore.selected.vatRegistered" value="true"/>
            <span class="customs-checkboxes.item.checkmark"></span>
        </label>
    </fieldset>
</template>

<script>
    import { useMainStore } from '@/stores/MainStore.ts';

    export default {
        setup() {
            const mainstore = useMainStore()
            return { mainstore }
        },

        name: 'CustomsCheckboxes',

        methods: {
            validateFields() {
                if(this.mainstore.selected.sendingGoods == 'no goods') {
                    this.mainstore.validations.customsValid = true;
                } else {
                    this.mainstore.validations.customsValid = false;
                }
            }
        }
    }

</script>