<template>
  <div class="hero-banner">
    <div class="hero-banner.container">
      <h1 class="hero-banner.title">{{ hero }}</h1>
      <span class="hero-banner.subtitle">for drop-off or collection</span>
      <KeyBenefit benefit="No registration required"/>
      <KeyBenefit benefit="Pay with your credit or debit card"/>
      <KeyBenefit benefit="Print your labels at home"/>
    </div>
  </div>
</template>

<script>
import KeyBenefit from '@/components/KeyBenefit.vue';

export default {
  name: 'HeroBanner',
  props: {
    hero: String
  },
  components: {
    KeyBenefit
  }
}
</script>
