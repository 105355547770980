<template>
  <PageTitle title="Basket" />
  <section class="main-basket container desktop-view --basket">
    <div class="desktop-view.sidebar-content">
      <div class="main-basket.total">
        <span class="main-basket.total.label">Basket total:</span>
        <span class="main-basket.total.price"
          >£{{ (basketTotal / 100).toFixed(2) }}
          <span v-if="basketStore.hasTaxCode" class="main-basket.total.vat"
            >incl.VAT</span
          ></span
        >
      </div>
      <MainButton
        class="main-button"
        style="margin-bottom: 20px"
        :class="{
          '--disabled':
            this.collectionStore.collectionData.collectionRequired === true &&
            basketStore.basketItems.some(
              (item) =>
                item.serviceDetails &&
                !item.serviceDetails.availableForCollection
            )
        }"
        btntxt="Checkout now"
        @click="handleClick"
      >
      </MainButton>

      <!--

                <div class="warning-text" v-if="this.collectionStore.collectionData.collectionRequired === true && basketStore.basketItems.some(item => item.serviceDetails && !item.serviceDetails.availableForCollection)">There are items in your basket that are not available for collection</div>

            -->

      <router-link
        class="secondary-button"
        to="/"
        style="margin-bottom: 20px"
        @click="mainstore.reset()"
      >
        <span class="secondary-button.text">Add another item</span>
      </router-link>

      <!--

                <TertiaryButton 
                v-if="collectionStore.collectionData.collectionRequired === false" 
                :btntxt="collectionButtonText" style="margin-bottom: 20px;" 
                @click="this.collection = true" 
                :disabled="basketStore.basketItems.some(item => item.serviceDetails && !item.serviceDetails.availableForCollection)"
                :class="{ '--disabled': basketStore.basketItems.some(item => item.serviceDetails && !item.serviceDetails.availableForCollection) }"
            />

            <TertiaryButton 
                v-else 
                btntxt="Remove collection" 
                option="--delete" 
                style="margin-bottom: 20px;" 
                @click="handleDelete"
            />

            -->
      <!--
            
                <div 
                class="warning-text" 
                v-if="basketStore.basketItems.some(item => item.serviceDetails && !item.serviceDetails.availableForCollection)"
            >
            
                One or more items in your basket are not available for collection
            </div>

            -->
    </div>
    <div class="desktop-view.main-content">
      <MainBasketItem
        v-for="item in basketStore.basketItems"
        :key="item"
        @delete="deleteShipment(item)"
      >
        <template v-slot:image
          ><img :src="require('@/assets/img/' + item.parcelSize.image)"
        /></template>
        <template v-slot:name>{{ formatDisplayValue(item.parcelSize.name) }}</template>
        <template v-slot:service>{{
          formatDisplayValue(item.serviceDetails.expandedName)
        }}</template>
        <template v-slot:recipient>{{
          item.recipientDetails.firstName
        }}</template>
        <template v-slot:destination>{{ item.destination.name }}</template>
        <template v-slot:weight>{{ item.parcelSize.maxWeight }}</template>
        <template v-slot:size>{{ item.parcelSize.size }}</template>
        <template v-slot:insurance v-if="item.additionalInsurance.value"
          >Insured up to: £{{ item.additionalInsurance.value / 100 }}</template
        >
        <template v-slot:price>
          £{{
            (
              (item.serviceDetails.priceInPence +
                (item.additionalInsurance
                  ? item.additionalInsurance.price
                  : 0)) /
              100
            ).toFixed(2)
          }}
          <span
            v-if="item.serviceDetails.taxCode !== null"
            class="main-basket-item.price.data.vat"
          >
            incl. VAT</span
          >
        </template>
        <template v-slot:address>
          <p>
            {{ item.recipientDetails.firstName }}
            {{ item.recipientDetails.lastName }}
          </p>
          <!--
                        <p>{{ item.destination.name }}</p>
                        <p v-for="value in Object.values(item.recipientDetails?.address)" :key="value">{{ value }}</p>
                    -->

          <p v-if="item.recipientDetails.address.RECIPIENTADDRESSLINE1">
            {{ item.recipientDetails.address.RECIPIENTADDRESSLINE1 }}
          </p>
          <p v-if="item.recipientDetails.address.RECIPIENTADDRESSLINE2">
            {{ item.recipientDetails.address.RECIPIENTADDRESSLINE2 }}
          </p>
          <p v-if="item.recipientDetails.address.RECIPIENTADDRESSLINE3">
            {{ item.recipientDetails.address.RECIPIENTADDRESSLINE3 }}
          </p>
          <p v-if="item.recipientDetails.address.RECIPIENTCITY">
            {{ item.recipientDetails.address.RECIPIENTCITY }}
          </p>
          <p v-if="item.recipientDetails.address.RECIPIENTCOUNTY">
            {{ item.recipientDetails.address.RECIPIENTCOUNTY }}
          </p>
          <p v-if="item.recipientDetails.address.RECIPIENTCOUNTRY">
            {{ item.recipientDetails.address.RECIPIENTCOUNTRY }}
          </p>
          <p>{{ item.recipientDetails.address.RECIPIENTPOSTCODE }}</p>
        </template>
      </MainBasketItem>
      <div
        class="page-error"
        v-if="
          basketStore.basketItems === null ||
          basketStore.basketItems.length === 0
        "
      >
        <span class="page-error.intro">You have no items in your basket</span>
        <span class="page-error.advice"
          >Please start adding items from the homepage</span
        >
        <div class="page-error.button">
          <router-link to="/" class="main-button"
            ><span class="main-button.text">Back to homepage</span></router-link
          >
        </div>
      </div>
      <CollectionItem
        v-if="collectionStore.collectionData.methodOfSupply === 'COLLECTION'"
      />
      <!--<CollectionForm @close="handleClose" v-if="this.collection === true"/>-->
    </div>
    <!-- VAT RECEIPT REQUEST MODAL - no longer required as we are sending VAT receipt to all users
            <div v-if="showModal" class="confirmation-modal">
                <div class="confirmation-modal.panel">
                    <span class="confirmation-modal.panel.close" @click="closeConfirmationModal"></span>
                    <div class="confirmation-modal.panel.content">
                        <span class="confirmation-modal.panel.content.intro">Business users only</span>
                        <span class="confirmation-modal.panel.content.title">Do you need a VAT receipt?</span>
                        <span class="confirmation-modal.panel.content.text">If you are purchasing on behalf of a VAT registered business, you may request a VAT receipt</span>
                        <div class="confirmation-modal.panel.content.buttons">
                            <TertiaryButton btntxt="Yes please" @click="continueToRoute()"/>
                            <MainButton btntxt="No thanks" @continue="continueToRoute()"/>
                        </div>
                    </div>
                </div>
            </div>
        -->
    <div v-if="showModal" class="billing-address-modal">
        <span
          class="billing-address-modal.close"
          @click="closeConfirmationModal"
        ></span>
      <div class="billing-address-modal.panel">
        <div class="billing-address-modal.panel.content">
          <span class="billing-address-modal.panel.content.title"
            >Please provide your billing address</span
          >
          <form class="billing-address-modal.panel.content.form">
            <div class="billing-address-modal.panel.content.form.checkbox">
              <input
                type="checkbox"
                id="use-senders-address"
                v-model="useSendersAddress"
                @change="populateSendersAddress"
              />
              <label for="use-senders-address">Use my sender's address</label>
            </div>
            <label for="address1">Address line 1</label>
            <input
              class="form-text-field"
              type="text"
              id="address-1"
              name="Address Line 1"
              v-model="basketStore.billingAddress.addressLine1"
            />
            <label for="address2">Address line 2</label>
            <input
              class="form-text-field"
              type="text"
              id="address-2"
              name="Address Line 2"
              v-model="basketStore.billingAddress.addressLine2"
            />
            <label for="address3">Address line 3</label>
            <input
              class="form-text-field"
              type="text"
              id="address-3"
              name="Address Line 3"
              v-model="basketStore.billingAddress.addressLine3"
            />
            <label for="address-city">Town/City</label>
            <input
              class="form-text-field"
              type="text"
              id="address-city"
              name="Town or City"
              v-model="basketStore.billingAddress.city"
            />
            <label for="address-county">County</label>
            <input
              class="form-text-field"
              type="text"
              id="address-county"
              name="County"
              v-model="basketStore.billingAddress.county"
            />
            <label for="address-country">Country</label>
            <select
              class="form-select-field"
              id="address-country"
              name="Country"
              v-model="basketStore.billingAddress.country"
            >
              <option
                v-for="option in countries"
                :key="option.alpha3Code"
                :value="option"
              >
                {{ option.name }}
              </option>
            </select>
            <label for="address-postcode">Postcode</label>
            <input
              class="form-text-field"
              id="address-postcode"
              name="Postcode"
              v-model="basketStore.billingAddress.postcode"
            />
          </form>
          <div class="billing-address-modal.panel.content.buttons">
            <MainButton
              btntxt="Continue"
              :class="{ '--disabled': !isFormValid }"
              @click="continueToRoute"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="confirmation-modal" v-if="mainstore.hasNavigatedFromCheckout">
      <div class="confirmation-modal.panel">
        <div class="confirmation-modal.panel.content">
          <h3 class="confirmation-modal.panel.content.title">There is an active payment in progress.</h3>
          <p class="confirmation-modal.panel.content.text">You can no longer edit your basket</p>
          <router-link to="/checkout" class="main-button" style="margin-bottom: 10px;"><span class="main-button.text">Continue with payment</span></router-link>
          <router-link to="/" class="main-button --back" @click="mainstore.fullReset(), collectionStore.resetCollection(), basketStore.resetBasket()"><span class="main-button.text">Start over</span></router-link>
        </div>
      </div> 
    </div>
  </section>
</template>

<script>

import { useFormStore } from '@/stores/FormStore.ts';
import { useMainStore } from '@/stores/MainStore.ts';
import { useBasketStore } from '@/stores/BasketStore.ts';
import { useCollectionStore } from '@/stores/CollectionStore.ts';
import PageTitle from '@/components/PageTitle';
import MainButton from '@/components/MainButton';
//import SecondaryButton from '@/components/SecondaryButton';
//import TertiaryButton from '@/components/TertiaryButton';
import MainBasketItem from '@/components/MainBasketItem';
//import CollectionForm from '@/components/CollectionForm';
import CollectionItem from '@/components/CollectionItem';
import axios from 'axios';
//import FormTextField from "@/components/form-components/FormTextField.vue";


export default {
    setup() {
        const store = useFormStore()
        const mainstore = useMainStore()
        const basketStore = useBasketStore()
        const collectionStore = useCollectionStore()
        return { store, mainstore, basketStore, collectionStore }
    },

    name: 'MainBasket',

    components: {
        PageTitle,
        MainButton,
        //SecondaryButton,
        //TertiaryButton,
        MainBasketItem,
        //CollectionForm,
        CollectionItem,
        //FormTextField
    },

    data() {
        return {
            collection: false,
            showModal: false,
            countries: [],
            useSendersAddress: false,
            showConfirmationModal: false
        }
    },

    computed: {
        /*
        // Removed as no longer required. User selects collection earlier in process
        collectionButtonText() {
            if (this.mainstore.collectionPrice === 0) {
                return 'Add collection for £0.00';
            } else if (this.collectionStore.collectionRequired === true) {
                return 'Edit collection';
            } else {
                return `Add collection for £${this.mainstore.collectionPrice.toFixed(2)}`;
            }
        },
        */

        /*
        // Removed as no longer required. User selects collection earlier in process
        checkoutButtonText() {
            if (this.collectionStore.collectionData.collectionRequired === true) {
                return 'Checkout now';
            } else {
                return 'Checkout without collection';
            }
        },
        */

        basketTotal() {
            if(this.collectionStore.collectionData.methodOfSupply === 'COLLECTION') {
                return this.mainstore.collectionPrice + this.basketStore.total
            } else {
                return this.basketStore.total
            }
        },

        isFormValid() {
            const address = this.basketStore.billingAddress;
            return address.addressLine1 !== '' && address.city !== '' && address.country !== null && address.postcode !== '';
        },

        senderDetails() {
            return this.basketStore.sendersDetails[0]?.address || {};
        }
    },

    watch: {
        useSendersAddress(newVal) {
            if (newVal) {
                this.populateSendersAddress();
            }
        }
    },

    methods: {

        deleteItem(item) {
            this.basketStore.removeItem(item);

            if (this.basketStore.basketCount < 1) {
                window.resetLocalStorage();
                this.mainstore.fullReset();
                this.collectionStore.resetCollection();
            }
        },

        handleClose() {
            this.collection = false;
            if(this.collectionStore.collectionData.collectionDate == null) {
                this.collectionStore.collectionData.collectionRequired = false;
            } else {
                this.collectionStore.collectionData.collectionRequired = true;
            }
        },

        handleDelete() {
            this.collectionStore.collectionData.collectionRequired = false;
            this.collectionStore.collectionData.collectionDate = null;
            this.collectionStore.collectionData.safePlace = '';
        },

        closeConfirmationModal() {
            this.showModal = false;
        },

        handleClick() {
           if(!this.collectionStore.collectionData.collectionRequired) {
                this.showModal = true;
            } else {
                this.$router.push('/checkout');
            }
        },

        continueToRoute() {
            this.collectionStore.customerAgrees = true;
            this.$router.push('/checkout');
        },

        deleteShipment(item) {
            //const payload = item.shipmentRef;
            // const xAuthToken = this.mainstore.tokens.xauthtoken;
            const consignment = this.basketStore.consignmentData.consignmentRef;
            let itemShipmentRef = item.shipmentRef;

            axios
                .delete(process.env.VUE_APP_API_CANCELSHIPMENT, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': process.env.VUE_APP_AUTHORIZATION,
                        // 'X-Auth-Token': xAuthToken
                    },
                    params: {
                        shipmentRef: itemShipmentRef,
                        consignmentRef: consignment
                    }
                })
                .then((response) => {
                    console.log('deleted:', response.data);
                    this.deleteItem(item);
                })
                .catch((error) => {
                    console.error(error);
                    //this.basketStore.resetBasket();
                    this.$router.push({
                        path: '/confirmation-error'
                    });
                });
        },

        populateSendersAddress() {
            if (this.useSendersAddress) {
                const senderAddress = this.senderDetails.address || {};
                this.basketStore.billingAddress.addressLine1 = senderAddress.ADDRESSLINE1 || '';
                this.basketStore.billingAddress.addressLine2 = senderAddress.ADDRESSLINE2 || '';
                this.basketStore.billingAddress.addressLine3 = senderAddress.ADDRESSLINE3 || '';
                this.basketStore.billingAddress.city = senderAddress.CITY || '';
                this.basketStore.billingAddress.county = senderAddress.COUNTY || '';
                const country = this.countries.find(country => country.alpha3Code === this.senderDetails.country) || null;
                this.basketStore.billingAddress.country = country;
                this.basketStore.billingAddress.postcode = senderAddress.POSTCODE || '';
            } else {
                // Reset to empty if checkbox is unchecked
                this.basketStore.billingAddress = {
                    addressLine1: '',
                    addressLine2: '',
                    addressLine3: '',
                    city: '',
                    county: '',
                    country: null,
                    postcode: ''
                };
            }
        },

        // TODO: Remove with pricing engine
        formatDisplayValue(value) {
            if (value == null) {
                return value;
            }
            if (value.includes("small parcel")) {
                return value.replace("small parcel", "parcel");
            } else if (value.includes("Small parcel")) {
                return value.replace("Small parcel", "Parcel");
            } else if (value.includes("Small Parcel")) {
                return value.replace("Small Parcel", "Parcel");
            }
            return value;
        }
    },

    mounted() {
        axios({
            method: 'get',
            url: process.env.VUE_APP_API_COUNTRIES,
            headers: {
              'Authorization': process.env.VUE_APP_AUTHORIZATION,
            }
        })
        .then(response => {
            this.countries = response.data.countryList.sort((a, b) => a.name.localeCompare(b.name));
        })
        .catch(err => console.error(err));
    },
}
</script>
