<template>
    <div class="main-basket-item">
        <button class="main-basket-item.delete" @click="deleteItem">Delete item</button>
        <!--
            Collection banner no longer required

            <transition 
                enter-active-class="fade-enter-active"
                enter-class="fade-enter"
                enter-to-class="fade-enter-to"
                leave-active-class="fade-leave-active"
                leave-class="fade-leave"
            >
                <div class="main-basket-item.collection-banner" v-if="collection === true">
                    <span class="main-basket-item.collection-banner.text">Collection</span>
                </div>
            </transition>
        -->
        <div class="main-basket-item.section.image">
            <slot name="image"></slot>
        </div>
        <div class="main-basket-item.section.text">
            <h2 class="main-basket-item.title"><slot name="name"></slot></h2>

            <span class="main-basket-item.service">Service: <slot name="service"></slot></span>
            <span class="main-basket-item.info">Going to <slot name="recipient"></slot> in <slot name="destination"></slot></span>
            <span class="main-basket-item.info">Up to: <slot name="weight"></slot>g</span>
            <span class="main-basket-item.info">Max size: <slot name="size"></slot></span>
            <span class="main-basket-item.insurance"><slot name="insurance"></slot></span>
            <!--
                Toggle collection no longer required
                <ToggleSwitch @toggle="handleToggle" v-if="this.collectionstore.collectionRequired === true"/>
            -->
            <div class="main-basket-item.accordion">
            <span class="main-basket-item.accordion.title" @click="toggleAccordion()">Details</span>
            <div class="main-basket-item.accordion.content" v-show="accordion">
                    <span class="main-basket-item.accordion.content.title">Sending to:</span>
                    <slot name="address"></slot>
                </div>
            </div>
        </div>
        <div class="main-basket-item.price">
            <span class="main-basket-item.price.data"><slot name="price"></slot></span>
        </div>
        <DeleteConfirmationModal
            v-if="showConfirmationModal"
            @confirm="confirmDelete"
            @cancel="cancelDelete"
        ></DeleteConfirmationModal>
    </div>
</template>

<script>
    import { useBasketStore } from '@/stores/BasketStore.ts';
    import { useMainStore } from '@/stores/MainStore.ts';
    import { useCollectionStore } from '@/stores/CollectionStore.ts';
    // import ToggleSwitch from '@/components/form-components/ToggleSwitch.vue';
    import DeleteConfirmationModal from '@/components/DeleteConfirmationModal.vue';

    export default {
        name: 'MainBasketItem',

        components: {
            // ToggleSwitch,
            DeleteConfirmationModal
        },

        emits: ["delete"],

        setup() {
            const basketstore = useBasketStore()
            const mainstore = useMainStore()
            const collectionstore = useCollectionStore()
            return {
                basketstore,
                mainstore,
                collectionstore
            }
        },

        data() {
            return {
                showConfirmationModal: false,
                collection: null,
                accordion: false
            }
        },

        methods: {
            confirmDelete() {
                this.$emit('delete');
                this.showConfirmationModal = false;
            },

            cancelDelete() {
                this.showConfirmationModal = false;
            },

            deleteItem() {
                this.showConfirmationModal = true;
            },

            handleToggle(toggle) {
                this.collection = toggle;
            },

            toggleAccordion() {
                this.accordion = !this.accordion;
            },
        }

    }
</script>

<style>
  .fade-item {
    opacity: 0;
  }
  .fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
  }
  .fade-enter, .fade-leave-to {
    opacity: 0;
  }
  .fade-enter-to {
    opacity: 1;
  }
</style>
