<template>
    <PageTitle title="How to use Online Postage"/>
    <section class="container">
        <div class="content rich-text-editor">
            <h2>Get started</h2>
            <ul>
                <li>Select the destination country to which you would like to send your item(s). The most popular options are displayed at the top of the list. You may either select from the list or begin typing the name of a country to narrow your choices.</li>
                <li>Enter the weight of the package. You may enter the weight in Grams or Kilograms.</li>
                <li>Click the <b>'Get Started'</b> button</li>
            </ul>
            <h2>Your item size</h2>
            <ul>
                <li>Select from the available size options for the destination and weight you have entered.</li>
                <li>Click the <b>'Continue'</b> button</li>
            </ul>
            <h2>Service</h2>
            <ul>
                <li>Select the compensation amount for the item you are sending. You should select a compensation value that most closely matches the value of the item you are sending. We provide a minimum compensation value of £20 in the event of loss or damage of your item.</li>
                <li>Select from the list of available services.</li>
                <li>Click the <b>'Continue'</b> button.</li>
            </ul>
            <h2>Destination details</h2>
            <ul>
                <li>Enter the details of the recipient of the item</li>
                <li>Enter the destination address. For UK, Isle of Man and channel Island addresses you may begin typing the address or postcode to select the address from a list.</li>
                <li>Click the <b>'Continue'</b> button</li>
            </ul>
            <h2>Senders' details</h2>
            <ul>
                <li>Enter the details of the person sending the item. You must provide an email address to enable us to send you an email containing your labels and receipt of purchase</li>
                <li>Enter the address of the sender. The address may only be a valid Isle of Man address. You may also begin typing a postcode to be presented with a list of addresses from which to choose.</li>
                <li>Click the <b>'Continue'</b> button</li>
            </ul>
            <h2>Customs information</h2>
            <ul>
                <li>Please select if you are sending goods or not, and please also let us know if you are sending on behalf of a VAT registered business.</li>
                <li>
                    If you are sending goods outside of the United Kingdom, Isle of Man or Channel Islands, Customs require further information about the items your package contains. You must enter the correct information for each item your package contains. If you are only sending documents, you do not need to provide any information for customs.
                    <ul>
                        <li>Enter a description of your item. <i>E.g. 'Books', 'Shoes', 'Towels' or 'Cutlery'</i></li>
                        <li>Select the country of origin of your item. </li>
                        <li>Enter the HS tariff code for the item. This can be found on the gov.uk website: <a href="https://www.gov.uk/trade-tariff" target="_blank" rel="noopener noreferrer">Trade Tariff: look up commodity codes, duty and VAT rates - GOV.UK (www.gov.uk)</a></li>
                        <li>Enter the quantity you are sending of that type of item. <i>E.g. If you are sending two books, enter '2'</i>.</li>
                        <li>Enter the weight of a single one of these items in Kilograms(Kg). The total weight will be displayed below.</li>
                        <li>Enter the value of a single one of these items in Pounds(£). The total value will be displayed below.</li>
                        <li>Add any other items you have within your package by selecting the <b>'Add another item'</b> button.</li>
                    </ul>
                </li>
                <li>If you selected that you are sending on behalf of a VAT registered business, please enter the senders EORI number and VAT registration number.</li>
                <li>Please select the category from the list that best represents your item(s).</li>
                <li>Click the <b>'Continue'</b> button</li>
            </ul>
            <h2>Order summary</h2>
            <ul>
                <li>Before adding to your basket, please check the details of the item you are sending.</li>
                <li>Click the <b>'Add to basket'</b> button</li>
            </ul>
            <h2>Safe to fly</h2>
            <ul>
                <li>Please confirm that your item is safe to fly</li>
            </ul>
            <h2>Basket</h2>
            <ul>
                <li>
                    From the basket you have a number of options:
                    <ul>
                        <li>You can add collection to your item(s). We will collect your item(s) from a location you specify. This can be either, the senders address or any alternative address you provide. You may also specify a safe place from which we will collect. You must ensure you leave your correctly labelled item in the specified safe place prior to collection by a member of our team.</li>
                        <li>You can add another item to your basket by selecting the <b>'add another item'</b> button</li>
                        <li>You can choose to complete your purchase through WorldPay.</li>
                    </ul>
                </li>
            </ul>
            <h2>Sending your item</h2>
            <ul>
                <li>Once your payment has been successfully processed, you will be presented with a confirmation page from which you can download your PDF labels and invoice.</li>
            </ul>
        </div>
    </section>
</template>

<script>
    import PageTitle from '@/components/PageTitle.vue';

    export default {
        name: 'HowtoContent',

        components: {
            PageTitle
        }
    }
</script>