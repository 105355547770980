<template>
    <section>
        <div class="intro-text">
            <span class="intro-text.emphasised">Please select from the following options</span>
            <span>Once you have selected how you will supply your items to us, all services purchased will be grouped according to this choice. You can change this by selecting reset all but you will lose any data you have previously entered including items in the basket.</span>
        </div>
        <fieldset>
            <label v-if="collectionMethod != null" class="provide-items" :class="{ '--selected': (collectionstore.collectionData.methodOfSupply === collectionMethod.consignmentType) }">
                <input type="radio" required name="methodofsupply" v-model="collectionstore.collectionData.methodOfSupply"
                    :value="collectionMethod.consignmentType" @change="validate(), clearData(), addConsignment(collectionMethod)">
                <h2 class="provide-items.title">{{ collectionMethod.title }}</h2>
                <div class="provide-items.image-container">
                    <img :src="require('@/assets/img/' + collectionMethod.image)" class="provide-items.image-container.image" />
                </div>
                <span class="provide-items.details">
                    <h2 class="provide-items.details.title">{{ collectionMethod.title }}</h2>
                    <p class="provide-items.details.info">{{ collectionMethod.info }}</p>
                    <ul class="provide-items.info.list">
                        <li v-for="rule in collectionMethod.rules" :key="rule" class="provide-items.details.rules">{{ rule }}</li>
                    </ul>
                    <p v-if="collectionMethod.id === 'collection' && mainstore.collectionPrice > 0" class="provide-items.details.price">
                        Collection cost: 
                        <span class="provide-items.details.price.value">£{{ mainstore.collectionPrice.toFixed(2)/100 }}</span>
                    </p>
                    <div v-else-if="collectionMethod.id === 'collection' && mainstore.collectionPrice === 0"  class="provide-items.details.price">
                        Collection is
                        <span class="provide-items.details.price.value">FREE</span>
                        <p>Introductory offer! For a limited time only</p>
                    </div>
                </span>
                <span class="provide-items.checkmark"></span>
            </label>

            <div class="provide-items.collection-container" v-if="collectionstore.collectionData.methodOfSupply === 'COLLECTION'">
                <div class="provide-items.address" v-if="collectionstore.collectionData.methodOfSupply === 'COLLECTION'">
                    <h2 class="provide-items.address.title">Where should we collect your items from?</h2>
                    <span class="provide-items.address.intro">This address must be your residential address.</span>
                    <TypeaheadAddress :valid="addressValid" @changed="validateAddress(), buttonValid()" />
                </div>

                <div class="provide-items.collection-date" v-if="collectionstore.collectionData.methodOfSupply === 'COLLECTION'">
                    <h2 class="provide-items.collection-date.title">
                        When would you like your item(s) collected?
                    </h2>
                    <label for="collection-date" class="form-label">Date</label>
                    <select name="collection-date" class="form-select-field" @change="dateValid = true"
                        v-model="this.collectionstore.collectionData.collectionDate">
                        <option :value="null" selected disabled>Please select...</option>
                        <option v-for="item in collectionDates" :key="item" :value="item.date">
                            {{ formatDate(item.date) }}
                        </option>
                    </select>
                </div>

                <div class="provide-items.safe-place">
                    <h2 class="provide-items.safe-place.title">
                        If you will not be able to hand your item(s) over, please outline a safe place from where we can collect your item
                        (optional)
                    </h2>
                    <label for="safeplace" class="form-label">Your safe place must be located at the collection address (50 character limit)</label>
                    <input type="text" class="form-text-field" v-model="this.collectionstore.collectionData.safePlace" maxlength="50"/>
                </div>
            </div>

            <div v-for="item in mainstore.supplyMethod" :key="item.consignmentType">
                <div v-if="item.consignmentType != 'COLLECTION'">
                    <label class="provide-items" :class="{ '--selected': (collectionstore.collectionData.methodOfSupply === item.consignmentType) }">
                        <input type="radio" required name="methodofsupply" v-model="collectionstore.collectionData.methodOfSupply"
                            :value="item.consignmentType" @change="validate(), clearData(), addConsignment(item)">
                        <h2 class="provide-items.title">{{ item.title }}</h2>
                        <div class="provide-items.image-container">
                            <img :src="require('@/assets/img/' + item.image)" class="provide-items.image-container.image" />
                        </div>
                        <span class="provide-items.details">
                            <h2 class="provide-items.details.title">{{ item.title }}</h2>
                            <p class="provide-items.details.info">{{ item.info }}</p>
                            <ul class="provide-items.info.list">
                                <li v-for="rule in item.rules" :key="rule" class="provide-items.details.rules">{{ rule }}</li>
                            </ul>
                        </span>
                        <span class="provide-items.checkmark"></span>
                    </label>
                </div>
            </div>

        </fieldset>
    </section>
    <!--<MethodModal :show="showWarningModal" @cancel="cancelChange" @proceed="confirmChange" />-->
</template>

<script>
import axios from "axios";
import { useMainStore } from '@/stores/MainStore.ts';
import { useCollectionStore } from '@/stores/CollectionStore.ts';
import { useBasketStore } from '@/stores/BasketStore.ts';
import TypeaheadAddress from '@/components/TypeaheadAddress.vue';
//import MethodModal from '@/components/MethodModal.vue';

export default {
    name: 'ProvideItems',

    components: {
        TypeaheadAddress,
        //MethodModal
    },

    setup() {
        const mainstore = useMainStore();
        const collectionstore = useCollectionStore();
        const basketstore = useBasketStore();
        return {
            mainstore,
            collectionstore,
            basketstore
        }
    },

    data() {
        return {
            addressValid: null,
            collectionDates: [],
            consignmentTypes: [],
            collectionMethod: null,
            //showWarningModal: false,
            //pendingMethodChange: null
        }
    },

    methods: {

        addConsignment(item) {
            this.basketstore.consignmentType = item.consignmentType;
        },

        validate() {
            const methodOfSupply = this.collectionstore.collectionData.methodOfSupply;
            //const collectionAddress = this.collectionstore.collectionData.collectionAddress;

            if (methodOfSupply === 'COLLECTION' && this.addressValid === true) {
                this.mainstore.validations.provideItemsValid = true;
            } else if (methodOfSupply === 'DROP_AT_COUNTER' || methodOfSupply === 'DROP_AT_POSTBOX' || methodOfSupply === 'POST_LITE') {
                this.mainstore.validations.provideItemsValid = true;
            } else {
                this.mainstore.validations.provideItemsValid = false;
            }
        },

        validateAddress() {
            const data = this.collectionstore.collectionData.collectionAddress;

            if (data.POSTCODE !== null) {
                this.addressValid = true;
            } else {
                this.addressValid = false;
            }
        },

        buttonValid() {
            const {
                addressValid
            } = this;

            this.mainstore.validations.provideItemsValid =
                addressValid;
        },

        clearData() {
            this.collectionstore.collectionData.collectionAddress = {};
            this.collectionstore.collectionData.collectionDate = null;
            this.collectionstore.collectionData.safePlace = '';
            this.mainstore.validations.methodOfSupply = '';
            this.mainstore.validations.serviceValid = false;
            this.mainstore.service = null;
            this.mainstore.serviceDetails = [];
        },

        fetchCollectionDates() {
            axios({
                method: "get",
                url: process.env.VUE_APP_API_COLLECTIONDATES,
                headers: {
                    'Authorization': process.env.VUE_APP_AUTHORIZATION,
                }
            })
                .then((response) => {
                    this.collectionDates = response.data.collectionDates;
                })
                .catch((err) => console.error(err));
        },

        formatDate(date) {
            const options = {
                weekday: "long",
                year: "numeric",
                month: "long",
                day: "numeric",
            };
            const formattedDate = new Date(date).toLocaleDateString("en-GB", options);
            return formattedDate;
        },

        /* onMethodChange(item) {
            if (this.basketstore.basketCount > 0 || this.mainstore.selected.parcelSize.length > 0) {
                this.pendingMethodChange = item;  // Store the method selection temporarily
                this.showWarningModal = true;  // Show the warning modal
            } else {
                this.applyMethodChange(item);
            }
        },
        cancelChange() {
            this.showWarningModal = false;  // Close the modal without making changes
        },
        confirmChange() {
            this.clearData();  // Clear the data as the user confirmed the change
            this.applyMethodChange(this.pendingMethodChange);
            this.showWarningModal = false;
        },
        applyMethodChange(item) {
            this.collectionstore.collectionData.methodOfSupply = item.consignmentType;
            this.addConsignment(item);
            this.mainstore.reset();  // Reset the store data
            this.basketstore.resetBasket();  // Reset the basket
        }
        */
    },

    mounted() {
        this.fetchCollectionDates();


        this.consignmentTypes = [];
        for (let method of this.mainstore.supplyMethod) {
            if (method.consignmentType == 'COLLECTION') {
                this.collectionMethod = method;
            } else {
                this.consignmentTypes.push(method);
            }
        }
    },
}

</script>