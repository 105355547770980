<template>
  <div class="key-benefit">
    <span>{{ benefit }}</span>
  </div>
</template>

<script>
export default {
  name: 'KeyBenefit',
  props: {
    benefit: String
  }
}
</script>
