import { defineStore } from 'pinia'

export const useFormStore = defineStore('FormStore', { 

  state: () => ({ 

    destination: null,
    destinationName: 'United Kingdom',
    weight: null,

    parcelSize: [],

    insurance: 20,
    selectedService: null,
    serviceDetails: {},
    sendingGoods: 'goods',
    vatRegistered: null,

    recipientFirstName: '',
    recipientLastName: '',
    recipientBusinessName: '',
    recipientContactTelephone: '',

    shipmentRef: "",
    shipmentFormat: {
      name: "",
      shipperLines: {},
      recipientLines: {},
      shipmentLines: {},
      packages: [
        {
          packageLines: {},
          items: [
            {
              itemLines: {}
            }
          ]
        }
      ]
    },

    sendersCountry: 'Isle of Man',

    sendersFirstName: '',
    sendersLastName: '',
    sendersContactTelephone: '',
    sendersEmailAddress: '',

    sendersAddressLine1: '',
    sendersAddressLine2: '',
    sendersAddressLine3: '',
    sendersTownCity: '',
    sendersCountyState: '',
    sendersPostcodeZip: '',

    productName: '',
    productDimensions: '',
    productImageRef: '',

    totalItemPrice: 0,

    safeToFlyLabels: [],
    confirmationSafe: false,
    confirmationTerms: false,

    customsData: {
      item: {
        itemId: null,
        itemDescription: '',
        countryOfOrigin: '',
        hsTariffCode: '',
        quantity: null,
        itemWeight: null,
        itemValue: null,
      },
      category: '',
      sendersEori: '',
      sendersVat: '',
    },

    validation: false,

    validations: {
      parcelsize: null,
      service: null,
      recipient: null,
      sender: null,
      customs: null,

    },

    basketItems: []
  }),

  getters: {

    recipientFullName: state => {
      return state.recipientFirstName + ' ' + state.recipientLastName
    },

    sendersFullName: state => {
      return state.sendersFirstName + ' ' + state.sendersLastName
    },

    total: state => {
      return state.basketItems.reduce((acc, val) => acc + val.price, 0) 
    },

    basketCount: state => {
      return state.basketItems.length
    },

    getLines: (state) => (linesName) => {
      return state.shipmentFormat[linesName];
    }

  },

  actions: {
    reset() {
      this.destination = '';
    },

    addToBasket(state, payload) {
      state.basketItems = state.basketItems + payload.value
    },

    putElement(linesName, newElement) {
      this.shipmentFormat[linesName][newElement.name] = newElement.value;
    }
  }
})