<template>
    <fieldset class="business-fields">
        <h2 class="business-fields.title">Business Information for Customs</h2>
        <div v-for="element in formFields" :key="element.name">
            <label class="form-label" :for="element.name">
                {{ element.fieldDescription }}
                <b class="form-label.required" v-if="element.required">*</b>
            </label>

            <div class="form-text-field.container" :class="{
                '--valid': element.isValid && element.interacted,
                '--invalid': !element.isValid && element.interacted
            }">
                <input
                    :id="element.name"
                    class="form-text-field"
                    :class="{ '--error': !element.isValid }"
                    type="text"
                    v-model="mainstore.selected[element.modelTo]"
                    @input="validateField(element)"
                    :required="element.required"
                    :maxlength="element.maxlength"
                    @blur="element.interacted = true"
                />
                <p class="error" v-if="!element.isValid && element.required">{{ element.fieldDescription }} is required.</p>
            </div>
        </div>
    </fieldset>
</template>

<script>
import { useMainStore } from '@/stores/MainStore.ts';

export default {
    name: 'BusinessFields',

    setup() {
        const mainstore = useMainStore();
        return { mainstore };
    },

    data() {
        return {
            formFields: [
                {
                    name: 'EORI Number',
                    type: 'text',
                    fieldDescription: 'Senders EORI Number',
                    placeholder: 'Eg. GB000654329000',
                    modelTo: 'eoriNumber',
                    required: true,
                    maxlength: 18,
                    sequence: 1,
                    isValid: true,
                    interacted: false
                },
                {
                    name: 'Vat Registration Number',
                    type: 'text',
                    fieldDescription: 'Senders VAT registration number',
                    placeholder: 'Eg. GB000654329',
                    modelTo: 'vatNumber',
                    required: true,
                    maxlength: 15,
                    sequence: 2,
                    isValid: true,
                    interacted: false
                },
                // {
                //     name: 'Certificate Number',
                //     type: 'text',
                //     fieldDescription: 'Senders Certificate number',
                //     placeholder: 'Certificate number',
                //     modelTo: 'certNumber',
                //     required: true,
                //     maxlength: 32,
                //     sequence: 3,
                //     isValid: true,
                //     interacted: false
                // },
                // {
                //     name: 'Export License',
                //     type: 'text',
                //     fieldDescription: 'Export License number',
                //     placeholder: 'Export License number',
                //     modelTo: 'exportLicenseNumber',
                //     required: false,
                //     maxlength: 15,
                //     sequence: 4,
                //     isValid: true,
                //     interacted: false
                // }
            ]
        };
    },

    methods: {
        validateField(element) {
            element.isValid = this.isFieldValid(element);
            this.checkOverallValidity();
        },
        isFieldValid(element) {
            if (!element.required) {
                return true;
            }
            const value = this.mainstore.selected[element.modelTo];
            return value && value.trim() !== '';
        },
        checkOverallValidity() {
            const allValid = this.formFields.every(element => !element.required || element.isValid);
            if (!allValid) {
                this.$emit('invalid');
            } else {
                this.$emit('valid');
            }
        }
    }
};
</script>
