<template>
  <fieldset v-if="fields != null" class="form">
    <div v-for="element in sortedFields" :key="element.type">
      <label :for="element.type" class="form-label">
        {{ element.fieldDescription }}<span v-if="element.required" class="form-label.required">*</span>
      </label>

      <div v-if="element.selection == null" class="form-text-field.container" :class="{ '--valid': isValid(element.type) }">
        <input
          v-if="element.dataType === 'STRING' || element.dataType === 'INTEGER'"
          :type="element.dataType === 'STRING' ? 'text' : 'number'"
          class="form-text-field"
          :name="element.type"
          v-model.trim="formData[element.type]"
          @blur="validateField(element)"
          @input="handleInputChange(element)"
          :maxlength="element.maxLength"
        />
        <p class="error" v-if="hasInteracted[element.type] && !isValid(element.type)">{{ element.validationText || 'Invalid input' }}</p>
      </div>

      <div v-else class="form-select-field.container" :class="{ '--valid': isValid(element.type) }">
        <select
          class="form-select-field"
          :name="element.type"
          v-model="formData[element.type]"
          @blur="validateField(element)"
          @change="handleInputChange(element)"
        >
          <option v-for="option in element.selection" :value="option.value" :key="option.value">{{ option.description }}</option>
        </select>
        <p class="error" v-if="hasInteracted[element.type] && !isValid(element.type)">{{ element.validationText || 'Invalid input' }}</p>
      </div>
    </div>
  </fieldset>
</template>

<script>
import { useMainStore } from "@/stores/MainStore.ts";

export default {

  name: "AddressForm",

  props: {
    payload: {
      type: Object,
      required: true
    }
  },

  emits: ["valid", "invalid", "changed"],
  setup() {
    const mainstore = useMainStore();
    return {
      mainstore
    };
  },

  data() {
    return {
      formData: {},
      validationStatus: {},
      hasInteracted: {}
    };
  },

  computed: {
    excludedFields() {
      return [
        "RECIPIENTCOUNTRY",
        "RECIPIENTNAME",
        "RECIPIENTVATNUMBER",
        "RECIPIENTPHONE",
        "RECIPIENTEMAIL"
      ];
    },
    sortedFields() {
      return Object.values(this.payload)
        .filter(field => !this.excludedFields.includes(field.type))
        .sort((a, b) => a.sequence - b.sequence);
    }
  },

  watch: {
    payload: {
      immediate: true,
      handler(newPayload) {
        this.fields = newPayload;
        this.loadFormData();
      }
    }
  },

  methods: {
    isValid(fieldName) {
      return this.validationStatus[fieldName] === true;
    },

    validateField(element) {
      if (this.excludedFields.includes(element.type)) {
        return;
      }

      const value = this.formData[element.type];
      let isValid = true;

      this.hasInteracted[element.type] = true;

      if (element.required && !value) {
        isValid = false;
      }
      if (element.regexp && value && !new RegExp(element.regexp).test(value)) {
        isValid = false;
      }
      if (element.maxLength && value && value.length > element.maxLength) {
        isValid = false;
      }
      

      this.validationStatus[element.type] = isValid;

      this.checkFormValidity();
    },

    checkFormValidity() {
      // const isFormValid = Object.keys(this.formData).every(field => {
      //   if (this.excludedFields.includes(field)) {
      //     return true;
      //   }
      //   return this.validationStatus[field];
      // });
      const isFormValid = this.sortedFields.every(field => {
        if (!field.required || this.excludedFields.includes(field.type)) {
          return true;
        }
        return this.validationStatus[field.type];
      })
      const countyField = this.sortedFields.find(field => field.type === 'RECIPIENTCOUNTY');
      let countyValid = null;

      if (countyField && countyField.required) {
        countyValid = !!this.mainstore.selected.recipientDetails.address.RECIPIENTCOUNTY;
      } else {
        countyValid = true;
      }

      if (isFormValid && countyValid) {
        this.$emit("valid");
      } else {
        this.$emit("invalid");
      }
  },

    loadFormData() {
      console.log("Loading form data...");
      let savedAddress = this.mainstore.selected.recipientDetails.address || {};
      console.log("Saved address: ", savedAddress);
      this.formData = savedAddress || {};
      console.log("Form data after loading: ", this.formData);

      // Validate all fields after loading the form data
      this.sortedFields.forEach(element => {
        if(this.mainstore.selected.recipientDetails.address.RECIPIENTADDRESSLINE1) {
          this.validateField(element);
        }
      })
    },

    handleInputChange(element) {
      if (element.type === 'RECIPIENTPOSTCODE') {
        this.formData[element.type] = this.formData[element.type].toUpperCase();
      }

      this.$emit("changed");
      this.mainstore.$patch((state) => {
        state.selected.recipientDetails.address = this.formData;
        state.selected.recipientDetails.address["RECIPIENTCOUNTRY"] = this.mainstore.selected.destination.name;
        state.hasChanged = true;
      });

      // Validate the modified field
      this.validateField(element);
    }
  }
};
</script>





