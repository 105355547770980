<template>
    <PageTitle title="Privacy Notice - Online Postage Purchasing"/>
    <section class="container">
        <div class="content rich-text-editor">
            <h3>Isle of Man Post Office - Controller </h3>
            <p>Isle of Man Post Office (IOMPO) is a Controller for all personal data processed by the IOMPO, staff or other appointed Officers for the purposes of the General Data Protection Regulation (Isle of Man) Order 2018.  The contact details for the IOMPO are Isle of Man Post Office, Postal Headquarters, Spring Valley Industrial Estate, Douglas, Isle of Man IM2 1AA. </p>
            <p>In addition to the information set out in the IOMPO privacy notice, we may also collect the following information about you and the recipient of the mail you are sending to: </p>
            <ul>
                <li>Senders:
                    <ul>
                        <li>Name, phone number, postal and email addresses including business details where applicable </li>
                        <li>Safeplace to collect from where applicable </li>
                        <li>Collection address where different senders address </li>
                        <li>Payment details which will not be stored by IOMPO </li>
                        <li>Plus if applicable VAT, Printed Postage Impressions (PPIs), Economic Operators Registration and Identification (EORI) numbers </li>
                    </ul>
                </li>
                <li>
                    Recipient's: 
                    <ul>
                        <li>Name, phone number, postal and email addresses including business details where applicable</li>
                        <li>VAT number if applicable</li>
                    </ul>
                </li>
            </ul>
            <p>Payment account details are not collected by IOMPO</p>
            <h3>How we will use the information, we collect about you </h3>
            <p>IOMPO will use your information to:</p>
            <ul>
                <li>To process the purchase of online postage and, where applicable, collection of mail </li>
                <li>Delivery of mail </li>
                <li>Maintain a record of the transaction </li>
                <li>Verify the information and documentation you have provided is correct </li>
                <li>Help prevent and detect crime including fraud, money laundering, identity theft or other criminal offences. </li>
            </ul>
            <p>This may include checks of publicly available information but in some cases, where it is necessary and relevant, the information you provide may be disclosed or shared with other organisations. This will only be done where there is a compliance with legal obligation requirement, a contractual necessity, or processing for a new purpose or consent for us to do so.</p>
            <h3>How we will share the information we collect about you </h3>
            <p>Third parties we may share your data with include, for example: </p>
            <ul>
                <li>Police or law enforcement agencies where there is a legal requirement to do so </li>
                <li>Courts on production of a valid court order </li>
                <li>Fraud prevention agencies </li>
                <li>Intersoft and Parcelforce for the production and viewing of delivery labels  </li>
                <li>When mailing items containing goods to outside of the UK, data is shared with Royal Mail, who then share this data with the CDS (Customs Declaration Service) and the recipient country of each item Information obtained or disclosed by third parties will not be used for any other purpose other than to verify the information and documentation provided is correct </li>
            </ul>
            <h3>Protecting your information </h3>
            <p>Isle of Man Post Office will: </p>
            <ul>
                <li>keep your information safe and secure in compliance with its information security policy </li>
                <li>only use and disclose your information as detailed above, where necessary </li>
                <li>only permit authorised staff to view your data </li>
                <li>retain the information for no longer than is necessary and your information will be permanently deleted once the timeframes set out below have been reached.</li>
            </ul>
            <h3>Transfer of information outside the EEA </h3>
            <p>Where we transfer your personal data outside the EEA, we will ensure that it is protected in a manner that is consistent with how your personal data will be protected by us in the EEA. This can be done in a number of ways, for instance: </p>
            <ul>
                <li>We will share your personal data with overseas postal authorities and carriers. If you are sending a letter or parcel overseas or receiving an item from overseas, we will provide the information required for customs and tax purposes or for security screening. This information will normally include: 
                
                    <ul>
                        <li>the sender’s name and address </li>
                        <li>the name and address, plus phone number if given of the recipient, and </li>
                        <li>details of the item’s contents. </li>
                    </ul>
                </li>
                <li>IOMPO has signed up to Data Sharing agreements with worldwide postal administrators through the Universal Postal Union.  </li>
                <li>the country that we send the data to might be approved by the European Commission or a relevant data protection authority; </li>
                <li>the recipient might have signed up to a contract based on “model contractual clauses” approved by the European Commission, obliging them to protect your personal data. </li>
            </ul>
            <p>In other circumstances the law may permit us to otherwise transfer your personal data outside the EEA. In all cases, however, we will ensure that any transfer of your personal data is compliant with data protection law. </p>
            <h3>More information </h3>
            <p>You can find out more information including:  </p>
            <ul>
                <li>Looking at the <a href="http://www.iompost.com/Privacy" target="_blank">Isle of Man Post Office Privacy Notices</a> </li>
                <li><a href="https://intersoft.co.uk/privacy-policy-cookie-notice-intersoft/" target="_blank">Intersoft Privacy Notice</a></li>
                <li><a href="https://www.parcelforce.com/privacy" target="_blank">Parcelforce Privacy Notice </a></li>
                <li>Asking to see your information or making a complaint if you feel that your information is not being handled correctly </li>
                <li>Making a subject access request which is a request for all of the personal data we hold about you. Details can be found at <a href="https://www.iompost.com/privacy-notices/" target="_blank">www.iompost.com/privacy-notices/</a>  or by contacting the Isle of Man Post Office Data Protection Officer </li>
            </ul>
            <h3>Your rights </h3>
            <h4>Right of access to personal information:</h4>
            <p>Isle of Man Post Office will provide any individual with access to their personal data upon request, unless, and to the extent necessary, that the right of access is restricted. Further details can be found on the IOMPO Privacy Notices webpage or by contacting the IOMPO Data Protection Officer. </p>
            <h4>Other rights: </h4>
            <p>If we do hold information about you, you can ask us to correct any mistakes. You also have the right, subject to any statutory limitations, to object to processing, to erasure or restriction of processing, and to data portability.  No automated decisions, or profiling, are undertaken by the Isle of Man Post Office. </p>
            <p>To make any request relating to your data held by IOMPO please contact the Data Protection Officer;</p>
            <h5>By post:</h5>
            <p>Isle of Man Post Office, Postal Headquarters, Spring Valley Industrial Estate, Douglas, Isle of Man IM2 1AA </p>
            <h5>By email:</h5>
            <p>dpo@iompost.com</p>
            <h5>By telephone: </h5>
            <p>01624 698485 </p>
            <p>If you are not satisfied with the response you receive, you may also complain to the Information Commissioner, whose details can be found on <a href="http://www.inforights.im" target="_blank">www.inforights.im</a>, or the relevant supervisory authority.  You may have a right to other remedies. </p>
            <h3>Isle of Man Post Office - Retention Periods</h3>
            <table>
                <tbody>
                    <tr>
                        <th>Categories of personal data / document type</th>
                        <th>Retention Periods</th>
                    </tr>
                    <tr>
                        <td>
                            <ul>
                                <li>Senders:
                                    <ul>
                                        <li>Name, phone number, postal and email addresses including business details where applicable </li>
                                        <li>Safeplace to collect from where applicable </li>
                                        <li>Collection address where different senders address </li>
                                        <li>Payment details which will not be stored by IOMPO </li>
                                        <li>Plus if applicable VAT, Printed Postage Impressions (PPIs), Economic Operators Registration and Identification (EORI) numbers </li>
                                    </ul>
                                </li>
                                <li>Recipient's: 
                                    <ul>
                                        <li>Name, phone number, postal and email addresses including business details where applicable </li>
                                        <li>VAT number if applicable </li>
                                    </ul>
                                </li>
                            </ul>
                        </td>
                        <td>
                            <p>Two years by IOMPO </p>
                            <p>Royal Mail - 13 months in the live database, then archived for approx. 5 years </p>
                            <p>Other receiving parties - to process the data only for the purpose of fulfilling the purchased service.</p>
                        </td>
                    </tr>
                    <tr>
                        <td>Payment details</td>
                        <td>No financial data retained by IOMPO </td>
                    </tr>

                </tbody>
            </table>
            <p>Date of Issue: 11/09/2024 </p>
            <p>Version control: 1.0</p>

        </div>
    </section>
</template>

<script>
    import PageTitle from '@/components/PageTitle.vue';

    export default {
        name: 'PrivacyPolicy',

        components: {
            PageTitle
        }
    }
</script>