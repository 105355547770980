<template>
    <div>
        <FormProgress />
        <PageTitle title="Please choose your letter or parcel size"/>
        <div class="container desktop-view">
            <div class="desktop-view.main-content">
                <router-view></router-view>
                <FormNavigation />
            </div>
            <div class="desktop-view.sidebar-content">
                <OrderSidebar/>
            </div>
        </div>
    </div>
</template>

<script>
    import FormProgress from '@/components/FormProgress.vue';
    import FormNavigation from '@/components/form-components/FormNavigation.vue';
    import OrderSidebar from '@/components/OrderSidebar.vue';
    import PageTitle from '@/components/PageTitle.vue';
    import { useMainStore } from '@/stores/MainStore.ts';
    //import OrderSummary from './OrderSummary.vue';


    export default {

        name: "OrderForm",

        components: {
            FormProgress,
            FormNavigation,
            OrderSidebar,
            PageTitle,
            //OrderSummary
        },

        setup() {
            const mainstore = useMainStore()
            return { mainstore }
        },
    }

</script>
