import { defineStore } from 'pinia';
import { useStorage } from '@vueuse/core';
import axios from 'axios';

export const useMainStore = defineStore('MainStore', { 
    
    state: () => ({ 

        products: [ 
            {
                id: 'letter',
                itemFormat: 'LETTER',
                name: 'Letter',
                image: 'letter.png',
                minWeight: 1,
                maxWeight: 100, 
                size: '24cm x 16.5cm x 0.5cm',
                info: null,
                restrictions: []
            },
            {
                id: 'largeletter',
                itemFormat: 'LARGE_LETTER',
                name: 'Large Letter',
                image: 'large-letter.png',
                minWeight: 1,
                maxWeight: 750, 
                size: '35.3cm x 25cm x 2.5cm',
                info: null,
                restrictions: []
            },
            {
                id: 'smallparcel',
                itemFormat: 'SMALL_PARCEL',
                name: 'Small Parcel',
                image: 'parcel.png',
                minWeight: 1,
                maxWeight: 20000,
                maxWeightInt: 2000, 
                size: '61cm x 46cm x 46cm',
                info: null,
                
                restrictions: []
            },
            // {
            //     id: 'largeparcel',
            //     itemFormat: 'PARCEL',
            //     name: 'Large Parcel',
            //     image: 'package.png',
            //     minWeight: 1,
            //     maxWeight: 30000,
            //     size: '3m combined length and width. Restictions may vary by destination. Please check here',
            //     info: 'https://www.parcelforce.com/worldwide-directory',
            //     restrictions: ['IM']
            // }
        ],

        supplyMethod: [
            {
                id: 'dropoffpostbox',
                title: 'Drop off at a postbox',
                info: 'Drop your item into a postbox',
                rules: [
                    'You must be able to print your labels at home',
                    'Option not available for Special Delivery - Next Day or Special Delivery - Two Day',
                ],
                image: 'parcel-postbox.png',
                hasPrinter: true,
                consignmentType: 'DROP_AT_POSTBOX'
            },
            {
                id: 'dropoffcounter',
                title: 'Drop off at a counter',
                info: 'Take your item(s) to one of our counters around the Island',
                rules: [
                    'Special Delivery - Next Day must be posted no later than 3pm at Isle of Man Post Office Headquarters only'
                ],
                image: 'post-office-counter.png',
                hasPrinter: false,
                consignmentType: 'DROP_AT_COUNTER'
            },
            {
                id: 'collection',
                title: 'Collection',
                info: 'A member of our postal team will collect from your home.',
                rules: [
                    'We will print and bring your labels when we collect',
                    'You can leave your item in a safe place at your address',
                    'Special Delivery - Next Day services are not available for collection'
                ],
                image: 'collection.png',
                hasPrinter: false,
                consignmentType: 'COLLECTION'
            },
            /*
            {
                id: 'postlite',
                title: 'Postlite',
                info: 'Take your items to a Postlite outlet along with your QR code',
                rules: [
                    'Special Delivery Next Day is not available',
                    'Special Delivery 2 Day is not available',
                ],
                image: 'postlite.png',
                hasPrinter: false,
                consignmentType: 'POST_LITE'
            },
            */

        ],

        insuranceLevels: [
             
            0,
            20,
            50,
            100,
            750,
            1000,
            2500
            
        ],

        collectionPrice: null,

        // useStorage ensures that the data is persisted in case of browser refresh

        selected: useStorage('Selected', {
            shipmentRef: null,
            shipperRef: '',
            consignmentRef: null,
            methodOfSupply: null,
            destination: { alpha2Code: 'GB', alpha3Code: 'GBR', name: 'United Kingdom', numericCode: '826', parcelForceCode: 'GB', region: 'Europe', webRiposteCode: 'UK' },
            weight: null,
            parcelSize: [],
            insurance: 0,
            additionalInsurance: null,
            service: null,
            serviceDetails: [],
            sendingGoods: null,
            recipientDetails: {
                firstName: '',
                lastName: '',
                telephone: '',
                address: {}
            },
            sendersDetails: {
                firstName: '',
                lastName: '',
                telephone: '',
                emailAddress: '',
                address: {},
                selectedAddressFormatted: null,
            },
            customsItems: [
                {
                    itemLines: {
                        PACKAGEID: '0',
                        TARIFFCODE: null,
                        TARIFFDESCRIPTION: null,
                        ITEMDESCRIPTION: null,
                        ITEMQUANTITY: '1',
                        ITEMVALUE: '0.01',
                        COUNTRYOFORIGIN: null,
                        NETWEIGHT: '0.001'
                    },
                },
            ],
            vatRegistered: null,
            category: '',
            eoriNumber: '',
            vatNumber: '',
            // certNumber: '',
            // exportLicenseNumber: '',
            safeToFlyItems: [],
            confirmationSafe: null,
            confirmationTerms: null
        }),

        validations: useStorage('Validations', {
            provideItemsValid: false,
            parcelSizeValid: false,
            serviceValid: false,
            recipientDetailsValid: false,
            sendersDetailsValid: false,
            customsValid: false,
            orderSummaryValid: false,
            safeToFlyValid: false,
        }),

        //tokens: useStorage('Tokens', {
        //    xauthtoken: null,
        //    expires: null
        //}),

        pageLoading: false,

        //Data property to track if the user has navigated back from checkout
        hasNavigatedFromCheckout: false
    }),

    //persist: {
    //    enabled: true,
    //    strategies: [
    //      {
    //        key: 'tokenstore',
    //        storage: localStorage,
    //        paths: ['tokens'] // Only persist the tokens state
    //      }
    //    ]
    //  },

    getters: {
        recipientFullName: state => {
            return state.selected.recipientDetails.firstName + ' ' + state.selected.recipientDetails.lastName
        },

        sendersFullName: state => {
            return state.selected.sendersDetails.firstName + ' ' + state.selected.sendersDetails.lastName
        },

        customsTotalValue: state => {
            let itemTotals = 0;

            state.selected.customsItems.forEach(function (item) {
                let itemValue = parseFloat(item.itemLines.ITEMVALUE) * 100;
                let total = itemValue * parseInt(item.itemLines.ITEMQUANTITY);
                itemTotals += total;
            })
            itemTotals /= 100;
            return itemTotals.toFixed(2);
        },

        customsTotalWeight: state => {
            let totalWeight = 0;
        
            state.selected.customsItems.forEach(function (item) {
                let weight = parseFloat(item.itemLines.NETWEIGHT) * 1000;
                let total = weight * parseInt(item.itemLines.ITEMQUANTITY);
                totalWeight += total;
            })
            totalWeight /= 1000;
            return totalWeight
        },

        collectionPricePounds: state => {
            let price = state.collectionPrice;

            return price.toFixed(2) / 100
        },

        /*

        servicePricePounds: state => {
            let servicePrice = 0; // Default value in case priceInPence is not available

            if (state.selected.serviceDetails && state.selected.serviceDetails.priceInPence) {
                servicePrice = state.selected.serviceDetails.priceInPence;
            } else {
                console.error("Price in pence is not available");
            }
            let insurancePrice = state.selected.additionalInsurance.price;

            let total = servicePrice + insurancePrice;

            return total.toFixed(2) / 100
        },
        */

        totalItemPrice: state => {
            let totalPrice = null; 
            // ERRORS ON THE priceInPence CAN BE IGNORED
            if (state.selected.additionalInsurance.price) {
                totalPrice = state.selected.additionalInsurance.price + state.selected.serviceDetails.priceInPence;
            } else {
                totalPrice = state.selected.serviceDetails.priceInPence;
            }
            return (totalPrice / 100).toFixed(2);
        },
        

        stringifyCustomsItems: state => {
            return state.selected.customsItems.map(item => ({
                ...item,
                itemLines: {
                    ...item.itemLines,
                    ITEMQUANTITY: item.itemLines.ITEMQUANTITY !== null ? item.itemLines.ITEMQUANTITY.toString() : null,
                    NETWEIGHT: item.itemLines.NETWEIGHT !== null ? item.itemLines.NETWEIGHT.toString() : null,
                    ITEMVALUE: item.itemLines.ITEMVALUE !== null ? item.itemLines.ITEMVALUE.toString() : null
                }
            }));
        }
    },

    actions: {
        updateAddress(state, payload) {
            state.selected.recipientDetails.address = { ...state.address, ...payload }
        },

        updateSendersAddress(state, payload) {
            state.selected.sendersDetails.address2 = payload;
        },

        /* async retrieveXAuthToken() {
            // Check if no token exists or if the existing token is older than 24 hours
            if (this.tokens.xauthtoken == null || this.tokens.expires < (Date.now() - (24 * 60 * 60 * 1000))) {
                console.log("No X-Auth-Token or token expired");
        
                const recaptchaToken = await this.$recaptcha('login');
        
                try {
                    const rawResponse = await fetch(process.env.VUE_APP_API_URL_TOKENGEN, {
                        method: 'POST',
                        headers: {
                            "Authorization": process.env.VUE_APP_AUTHORIZATION,
                            "Content-Type": "application/json"
                        },
                        body: JSON.stringify({
                            service: process.env.VUE_APP_RECATCHA_SERVICE,
                            extraInfo: recaptchaToken
                        })
                    });
        
                    const authResponse = await rawResponse.json();
        
                    if (authResponse.httpCode === 500) {
                        console.log("AUTH ERROR: " + authResponse.message);
                        return false;
                    }
        
                    this.tokens.xauthtoken = authResponse.token;
                    this.tokens.expires = authResponse.expires;
        
                    return this.tokens.xauthtoken;
                } catch (error) {
                    console.error("Error while retrieving X-Auth-Token:", error);
                    return false;
                }
            }
        
            return this.tokens.xauthtoken;
        },*/

        retrieveCollectionFee() {
            axios({
                method: 'get',
                url: process.env.VUE_APP_API_COLLECTIONFEE,
                auth: {
                    username: 'public',
                    password: 'public'
                }
            })
            .then(response => {
                this.collectionPrice = response.data.currentFee.fee;
            })
            .catch(err => console.error(err));
        },

        setNavigatedFromCheckout(value) {
            this.hasNavigatedFromCheckout = value;
        },

        reset() {
            const originalSendersDetails = this.selected.sendersDetails;
            const selectedDestination = this.selected.destination;
            //const tokenData = this.tokens;
 
            this.selected = 
                {
                    shipmentRef: null,
                    shipperRef: '',
                    methodOfSupply: null,
                    destination: { alpha2Code: 'GB', alpha3Code: 'GBR', name: 'United Kingdom', numericCode: '826', parcelForceCode: 'GB', region: 'Europe', webRiposteCode: 'UK' },
                    weight: null,
                    parcelSize: [],
                    insurance: 0,
                    additionalInsurance: 0,
                    service: null,
                    serviceDetails: [],
                    sendingGoods: null,
                    recipientDetails: {
                        firstName: '',
                        lastName: '',
                        telephone: '',
                        address: {}
                    },
                    sendersDetails: originalSendersDetails,
                    customsItems: [
                        {
                            itemLines: {
                                PACKAGEID: '0',
                                TARIFFCODE: null,
                                TARIFFDESCRIPTION: null,
                                ITEMDESCRIPTION: null,
                                ITEMQUANTITY: '1',
                                ITEMVALUE: '0.01',
                                COUNTRYOFORIGIN: null,
                                NETWEIGHT: '0.001'
                            },
                        },
                    ],
                    vatRegistered: null,
                    category: '',
                    eoriNumber: '',
                    vatNumber: '',
                    // certNumber: '',
                    safeToFlyItems: [],
                    confirmationSafe: null,
                    confirmationTerms: null
                };
            
            this.validations = {
                provideItemsValid: false,
                parcelSizeValid: false,
                serviceValid: false,
                recipientDetailsValid: false,
                sendersDetailsValid: false,
                customsValid: false,
                orderSummaryValid: false,
                safeToFlyValid: false,
            },

            //this.tokens = tokenData,

            this.pageLoading = false,

            this.hasNavigatedFromCheckout = false
        },

        fullReset() {
            //const tokenData = this.tokens;

            this.selected = 
            {
                shipmentRef: null,
                shipperRef: '',
                consignmentRef: null,
                methodOfSupply: null,
                destination: { alpha2Code: 'GB', alpha3Code: 'GBR', name: 'United Kingdom', numericCode: '826', parcelForceCode: 'GB', region: 'Europe', webRiposteCode: 'UK' },
                weight: null,
                parcelSize: [],
                insurance: 0,
                additionalInsurance: null,
                service: null,
                serviceDetails: [],
                sendingGoods: null,
                recipientDetails: {
                    firstName: '',
                    lastName: '',
                    telephone: '',
                    address: {}
                },
                sendersDetails: {
                    firstName: '',
                    lastName: '',
                    telephone: '',
                    emailAddress: '',
                    address: {},
                },
                customsItems: [
                    {
                        itemLines: {
                            PACKAGEID: '0',
                            TARIFFCODE: null,
                            TARIFFDESCRIPTION: null,
                            ITEMDESCRIPTION: null,
                            ITEMQUANTITY: '1',
                            ITEMVALUE: '0.01',
                            COUNTRYOFORIGIN: null,
                            NETWEIGHT: '0.001'
                        },
                    },
                ],
                vatRegistered: null,
                category: '',
                eoriNumber: '',
                vatNumber: '',
                // certNumber: '',
                // exportLicenseNumber: '',
                safeToFlyItems: [],
                confirmationSafe: null,
                confirmationTerms: null
            };

            this.validations = {
                provideItemsValid: false,
                parcelSizeValid: false,
                serviceValid: false,
                recipientDetailsValid: false,
                sendersDetailsValid: false,
                customsValid: false,
                orderSummaryValid: false,
                safeToFlyValid: false,
            },

            //this.tokens = tokenData,

            this.pageLoading = false,

            this.hasNavigatedFromCheckout = false
        }
    }

    //persist: true,
 
})
