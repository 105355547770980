<template>
    <router-link :to="link" class="form-step-icon" :class="{'--disabled': this.validation == false}">
        <span class="form-step-icon.number number" v-if="validation === false">{{ count }}</span>
        <span class="form-step-icon.number --valid number" v-else></span>
        <span class="form-step-icon.text text">{{ title }}</span>
    </router-link>
</template>

<script>
    import { useMainStore } from '@/stores/MainStore.ts';

    export default {
        setup() {
            const mainstore = useMainStore()
            return { mainstore }
        },

        name: 'FormstepIcon',

        props: {
            count: Number,
            title: String,
            link: String,
            validation: Boolean
        },

        computed: {
            getUrl() {
                return window.location.pathname
            }
        }
    }
</script>

<style scoped>

a.router-link-active .number {
    border: 4px solid #5c43e0;
    opacity: 1;
}

a.router-link-active .text {
    opacity: 1;
}
</style>