<template>
    <button :class="'tertiary-button' + ' ' + option">
      <span class="tertiary-button.text">{{ btntxt }}</span>
    </button>
</template>

<script>
export default {
  name: 'TertiaryButton',
  props: {
    btntxt: String,
    option: String
  }
}
</script>