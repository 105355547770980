<template>
    <div class="delete-confirmation-modal">
        <div class="delete-confirmation-modal.content">
            <h2 class="delete-confirmation-modal.content.text">Are you sure you want to delete this item?</h2>
            <div>
                <button class="delete-confirmation-modal.content.confirm-button" @click="confirmDelete">Yes</button>
                <button class="delete-confirmation-modal.content.cancel-button" @click="cancelDelete">No</button>
            </div>
        </div>
    </div>
  </template>

  <script>
  export default {
    name: "DeleteConfirmationModal",

    emits: ['confirm', 'cancel'],

    methods: {
      confirmDelete() {
        this.$emit('confirm');
      },
      cancelDelete() {
        this.$emit('cancel');
      },
    },
  };
  </script>